import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@mui/material/Button";
import { Box, Stack, CircularProgress, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { FlexData } from "../../data/flexdata";
import { v4 as uuidv4 } from "uuid";
import { UpsertConfiguration } from "../../services/Configuration.Service";
import { GetConfigurationList } from "../../services/Configuration.Service";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

const FlexManagement = ({ BrandList, configList }) => {
  let intialBrandId = null;

  const [myFlexData, setmyFlexData] = React.useState(configList);
  const [InitialFlexData, setInnitialFlexData] = useState([]);
  const [tabIndex, setTabValue] = React.useState("1");
  const [loading, setLoading] = React.useState(false);
  const [Reload, setReload] = useState(false);
  const [ValidationError, setValidationError] = useState([]);
  const [GetDataLoad, setDataLoad] = useState(false);

  const confirm = useConfirm();

  const getConfigData = async () => {
    try {
      setDataLoad(true);
      var response = await GetConfigurationList("Flex", "", "");
      if (response?.isSuccess == true) {
        response?.result.forEach((element) => {
          element.configJson = JSON.parse(element.configJson);
        });

        let PreparedData = [];

        response?.result.forEach((element) => {
          element.configJson?.ConfigurationManagement[0]?.club?.forEach(
            (element1) => {
              // console.log(element1);
              PreparedData.push({
                primaryId: element.Id,
                clubType: element1.clubType,
                configId: element1.configId,
                configName: element1.configName,
                friendlyName: element1.friendlyName,
                isDeleted: element1.isDeleted,
                manufacturer: element1.manufacturer,
                price: element1.price,
                sku: element1.sku,
                status: element1.status,
                Brand: element.Brand,
                configurationType: element.configurationType,
              });
            }
          );
        });
        console.log(PreparedData);
        setmyFlexData(PreparedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoad(false);
    }
  };

  useEffect(() => {
    getConfigData();
  }, [Reload]);

  const findMaxValue = async () => {
    let maxId = 0;
    maxId = myFlexData
      ?.filter((item) => item.configurationType == "Flex")
      ?.reduce((max, obj) => (obj.configId > max ? obj.configId : max), 0);

    maxId = maxId + 1;

    console.log("The maximum ID is:", maxId);

    return maxId;
  };

  const addNewItem = async () => {
    var data = myFlexData.find(
      (item) => item.isDeleted == false && item.configurationType == "Flex"
    );

    const newitem = {
      primaryId: data?.primaryId ? data.primaryId : 0,
      configId: await findMaxValue(),
      configName: null,
      friendlyName: null,
      manufacturer: null,
      Brand: null,
      clubType: null,
      configurationType: "Flex",
      price: 0,
      sku: null,
      status: true,
      isDeleted: false,
    };

    const validationKey = `${newitem.configId}_${newitem.primaryId}_${newitem.configurationType}`;
    setValidationError((prevErrors) => [...prevErrors, validationKey]);

    myFlexData.push(newitem);
    setmyFlexData(Array.from(myFlexData));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      var data = myFlexData.filter((item) => item.configurationType == "Flex");
      let primaryId = 0;

      var consolidatedData = [];
      data.forEach((element) => {
        if (element.configName != "" && element.configName.trim().length >= 1) {
          primaryId = element.primaryId;
          consolidatedData.push({
            configId: element.configId,
            clubType: null,
            configName: element.configName,
            friendlyName: element.friendlyName,
            manufacturer: element.manufacturer,
            price: element.price,
            sku: element.sku,
            status: element.status,
            isDeleted: element.isDeleted,
            configurationType: element.configurationType,
          });
        }
      });

      var finalData = {
        ConfigurationManagement: [
          {
            id: primaryId,
            brand: null,
            configurationType: "Flex",
            club: consolidatedData,
          },
        ],
      };

      console.log(finalData);

      var RequestData = {
        Id: primaryId,
        Brand: null,
        configurationType: "Flex",
        configMasters: JSON.stringify(finalData),
      };

      var response = await UpsertConfiguration(RequestData);

      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setReload(!Reload);
    }
  };

  return (
    <Grid container spacing={2} marginTop={6}>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Stack direction="row" useFlexGap flexWrap="wrap">
          {myFlexData
            .filter(
              (item) =>
                item.configurationType == "Flex" && item.isDeleted == false
            )
            .map((listItem, index) => (
              <Box
                sx={{
                  marginRight: 4,
                  marginTop: 3,
                  height: 40,
                  backgroundColor: "#F2F0F0",
                  border: "1px solid #bdbdbd",
                  width: "auto",
                  borderRadius: 16,
                  border: ValidationError.includes(
                    `${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}`
                  )
                    ? "1px solid red"
                    : "1px solid #bdbdbd",
                }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                key={`${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}_${index}`}
              >
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  checked={listItem.status}
                  onChange={(event) => {
                    setmyFlexData((prevItems) =>
                      prevItems.map((item) =>
                        item.configId === listItem.configId
                          ? { ...item, status: event.target.checked }
                          : item
                      )
                    );
                  }}
                />
                <TextField
                  name={listItem.configId}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  defaultValue={listItem.configName?.toString()}
                  placeholder="Enter Value"
                  sx={{
                    marginTop: 0.6,
                    marginLeft: 0.5,
                    width: `${
                      listItem.configName.length > 0
                        ? listItem.configName.length * 9
                        : 100
                    }px`,
                  }}
                  onChange={(event) => {
                    const validationKey = `${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}`;
                    setmyFlexData((prevItems) =>
                      prevItems.map((item) =>
                        item.configId === listItem.configId
                          ? {
                              ...item,
                              configName: event.target.value,
                            }
                          : item
                      )
                    );

                    if (event.target.value.trim().length < 2) {
                      setValidationError((prevErrors) =>
                        prevErrors.filter((error) => error !== validationKey)
                      );
                    }
                  }}
                  onBlur={(e) => {
                    const validationKey = `${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}`;
                    if (e.target.value == null || e.target.value.trim() == "") {
                      console.log("1");
                      setValidationError((prevErrors) => [
                        ...prevErrors,
                        validationKey,
                      ]);
                    } else if (
                      myFlexData.filter(
                        (obj) =>
                          obj.configurationType == "Flex" &&
                          obj.configName.trim().toLowerCase() ==
                            e.target.value.trim().toLowerCase()
                      ).length > 1
                    ) {
                      console.log("2");
                      setValidationError((prevErrors) => [
                        ...prevErrors,
                        validationKey,
                      ]);
                    } else {
                      console.log("3");
                      setValidationError((prevErrors) =>
                        prevErrors.filter((error) => error !== validationKey)
                      );
                    }
                  }}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    confirm({
                      description: "Do you want to delete this record ?",
                    })
                      .then(() => {
                        setmyFlexData((prevItems) =>
                          prevItems.map((item) =>
                            item.configId === listItem.configId
                              ? { ...item, isDeleted: true }
                              : item
                          )
                        );
                        const validationKey = `${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}`;

                        setValidationError((prevErrors) =>
                          prevErrors.filter((error) => error !== validationKey)
                        );
                      })
                      .catch(() => {});
                  }}
                >
                  <CancelIcon sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                </IconButton>
                {ValidationError.includes(
                  `${listItem.configId}_${listItem.primaryId}_${listItem.configurationType}`
                ) ? (
                  listItem.configName == null ||
                  listItem.configName.trim() == "" ? (
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      color="primary"
                    >
                      Flex name is mandatroy
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      color="primary"
                    >
                      Flex name already exists
                    </Typography>
                  )
                ) : null}
              </Box>
            ))}

          {GetDataLoad && (
            <Typography
              variant="h2"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Loading...
            </Typography>
          )}

          {!GetDataLoad &&
            myFlexData.filter(
              (item) =>
                item.configurationType == "Flex" && item.isDeleted == false
            ).length <= 0 && (
              <h3 style={{ marginTop: 30, marginRight: 5 }}>
                No records found. Click 'Add New' to create a new one.
              </h3>
            )}

          {!GetDataLoad && (
            <Button
              variant="contained"
              tabIndex={-1}
              sx={{ marginRight: 4, marginTop: 3, height: 40 }}
              startIcon={<AddCircleTwoToneIcon />}
              onClick={addNewItem}
            >
              Add New
            </Button>
          )}
        </Stack>

        {!GetDataLoad && (
          <Box
            sx={{
              marginTop: 10,
              marginLeft: 20,
              marginRight: 20,
              paddingBottom: 10,
            }}
          >
            <Button
              sx={{ width: { sm: "100%" } }}
              component="label"
              size="large"
              variant="contained"
              tabIndex={-1}
              startIcon={<SaveTwoToneIcon />}
              onClick={handleSubmit}
              disabled={ValidationError.length > 0 ? true : false}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Commit Changes"
              )}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default FlexManagement;
