import * as React from "react";
import BarChart from "../../components/BarChart";
import { Box, useScrollTrigger } from "@mui/material";
import Header from "../../components/Header";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import Button from "@mui/material/Button";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import SportsGolfTwoToneIcon from "@mui/icons-material/SportsGolfTwoTone";
import SportsHockeyTwoToneIcon from "@mui/icons-material/SportsHockeyTwoTone";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, LinearProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NotificationsIcon from "@mui/icons-material/Notifications";

// import { useTheme } from '@mui/material';
// import { tokens } from '../../theme';
import { ClubList } from "../../data/clublist";
import { toast } from "react-toastify";

//import ClubModelsAccordion from "./club-models-accordion";
//import ShaftManagement from "./shafts-management";
import { lazy, Suspense } from "react";
import { SaveCustomFitDataEdit } from "../../services/Club.Service";
const ClubModelsAccordion = lazy(() => import("./club-models-accordion"));
const ShaftManagement = lazy(() => import("./shafts-management"));

const ClubManagementEditData = ({
  ClubCvsList,
  setSourceDataChanged,
  DataReFetech,
  setDataReFetech,
  category,
  BrandName,
}) => {
  const [value, setValue] = React.useState("1");
  const [DataCheck, setDataCheck] = React.useState(false);
  const [ButtonStatus, setButtonStatus] = React.useState(false);
  const [Saveloading, setSaveloading] = React.useState(false);
  const [APIData, setAPIData] = React.useState(ClubCvsList);
  const [SourceData, setSourceData] = React.useState(
    JSON.parse(JSON.stringify(ClubCvsList))
  );

  const [DupError, setDupError] = React.useState([]);
  const [ErrorText, setErrorText] = React.useState("");
  const [DuplicateModel, setDuplicateModel] = React.useState([]);
  const [DuplicateShaft, setDuplicateShaft] = React.useState([]);

  const findShaftMasterDuplicates = async (arr) => {
    const seen = new Set();
    const duplicates = [];

    for (const item of APIData?.shaftMasterData?.configurationManagement[0]?.club.filter(
      (element) => element.isDeleted == false
    )) {
      const compositeKey = `${item.configName.toLowerCase()}|${item.price}|${
        item.sku
      }|${item.status}|${item.isDeleted}`;

      if (seen.has(compositeKey)) {
        duplicates.push(item);
        setDuplicateShaft(Array.from(DuplicateShaft.push(item.configId)));
      } else {
        seen.add(compositeKey);
      }
    }
    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Shaft Masters")));
    }

    return duplicates;
  };

  const findModelMasterDuplicates = async (arr) => {
    const seen = new Set();
    const duplicates = [];

    for (const item of APIData?.modelMasterDatas.filter(
      (item) => item.status == true
    )) {
      const compositeKey = `${item.modelName.toLowerCase()}|${item.status}`;

      if (seen.has(compositeKey)) {
        duplicates.push(item);
        setDuplicateModel(Array.from(DuplicateModel.push(item.modelId)));
      } else {
        seen.add(compositeKey);
      }
    }
    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Models Masters")));
    }
    return duplicates;
  };

  const findTableModelConfigDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData.modelConfigDatas
      .filter((element) => element.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.modelId}-${item.configDetails.sex}-${item.configDetails.dexterity}-${item.configDetails.flex}-${item.configDetails.loft}-${item.status}-${item.configDetails?.bounce}-${item.configDetails?.grind}-${item.configDetails?.type}-${item.configDetails?.neck}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateModel(Array.from(DuplicateModel.push(item.modelId)));
        } else {
          lookup[key] = true;
        }
      });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Configuration")));
    }

    return duplicates;
  };

  const findFlexDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ShaftFlex" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateShaft(Array.from(DuplicateShaft.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });
    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Shaft Flex")));
    }

    return duplicates;
  };

  const findShaftDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ModelShaft" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateModel(Array.from(DuplicateModel.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Shaft")));
    }
    // console.log(duplicates);
    return duplicates;
  };

  const findGripDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ModelGrip" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateModel(Array.from(DuplicateModel.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Grip")));
    }
    // console.log(duplicates);
    return duplicates;
  };

  React.useEffect(() => {
    console.log(APIData);

    JSON.stringify(APIData) === JSON.stringify(SourceData)
      ? setSourceDataChanged(false)
      : setSourceDataChanged(true);

    CheckButtonDisbaled();
  }, [DataCheck]);

  const CheckButtonDisbaled = async () => {
    var shaft = await findShaftMasterDuplicates();
    var Model = await findModelMasterDuplicates();
    var TableData = await findTableModelConfigDuplicates();
    var FlexData = await findFlexDuplicates();
    var shaftData = await findShaftDuplicates();
    var gripData = await findGripDuplicates();

    console.log(
      `${shaft.length}-${Model.length}-${TableData.length}-${FlexData.length}-${shaftData.length}`
    );

    console.log(DuplicateModel);

    if (
      shaft.length > 0 ||
      Model.length > 0 ||
      TableData.length > 0 ||
      FlexData.length > 0 ||
      shaftData.length > 0 ||
      gripData.length > 0
    ) {
      console.log(DupError);
      var text = DupError.join(", ");

      setErrorText(`There was duplicate data in ${text}. Kindly check.`);

      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const saveEditedData = async () => {
    setSaveloading(true);
    // console.log(APIData);
    try {
      var response = await SaveCustomFitDataEdit(APIData);

      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaveloading(false);
      setSourceDataChanged(false);
      setDataReFetech(!DataReFetech);
    }
  };

  return (
    <React.Fragment>
      <Box m="20px">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab icon={<SportsGolfTwoToneIcon />} label="Models" value="1" />
              <Tab
                icon={<SportsHockeyTwoToneIcon />}
                label="Shafts"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Suspense fallback={<h1>Still Loading…</h1>}>
              <ClubModelsAccordion
                APIData={APIData}
                DataCheck={DataCheck}
                setDataCheck={setDataCheck}
                needEdit={true}
                category={category}
              />
            </Suspense>
          </TabPanel>
          <TabPanel value="2">
            <Suspense fallback={<h1>Still Loading…</h1>}>
              <ShaftManagement
                data={APIData}
                APIData={APIData}
                DataCheck={DataCheck}
                setDataCheck={setDataCheck}
                needEdit={true}
                category={category}
                BrandName={BrandName}
              />
            </Suspense>
          </TabPanel>
        </TabContext>
        {ButtonStatus ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Alert severity="error">{ErrorText}</Alert>
          </Box>
        ) : null}
        <Box sx={{ margin: 3, paddingBottom: 10 }}>
          <Button
            sx={{ width: { sm: "100%" } }}
            component="label"
            variant="contained"
            tabIndex={-1}
            size="large"
            disabled={ButtonStatus}
            startIcon={Saveloading ? null : <SaveTwoToneIcon />}
            onClick={(e) => saveEditedData()}
          >
            {Saveloading ? (
              <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
            ) : (
              "Commit Changes"
            )}
          </Button>
        </Box>
      </Box>
      <Dialog open={Saveloading} style={{ zIndex: 10001 }}>
        <LinearProgress color="primary" />
        <DialogTitle>
          <Typography
            color="primary"
            variant="h5"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <NotificationsIcon color="primary" sx={{ marginRight: 1 }} />
            Notification - Commit changes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Commit changes of data has started. It will take few mins to
            complete
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ClubManagementEditData;
