import { Box, Skeleton, ListItem, ListSubheader, List } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import Header from "../../components/Header";
import BounceManagement from "./bounce-management";
import FlexManagement from "./flex-management";
import GrindManagement from "./grind-management";
import GripsManagement from "./grips-management";
import LieManagement from "./lie-management";
import LoftManagement from "./loft-management";
import LoftAdjustment from "./loft-Adjustment";
import TypeManagement from "./type-management";
import NeckManagement from "./neck-management";

import { GetBrandsList } from "../../services/Brand.service";
import { GetConfigurationList } from "../../services/Configuration.Service";
import { json } from "react-router-dom";

const ConfigurationManagement = () => {
  const [pageTitle, setPageTitle] = React.useState("Grips Management");
  const [selectedConfig, setSelectedConfig] = React.useState("0");
  const [BrandList, setBrandList] = React.useState([]);
  const [ConfigList, setConfigList] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);

  const getBrands = async () => {
    try {
      var response = await GetBrandsList();
      if (response?.isSuccess == true) {
        var BrandData = [];
        response?.result.forEach((element) => {
          BrandData.push(JSON.parse(element.brandJson));
        });
        setBrandList(BrandData);
      }
    } catch (error) {}
  };

  const getConfigData = async () => {
    try {
      var response = await GetConfigurationList("");
      if (response?.isSuccess == true) {
        response?.result.forEach((element) => {
          element.configJson = JSON.parse(element.configJson);
        });

        let PreparedData = [];

        response?.result.forEach((element) => {
          //console.log(element);

          element.configJson?.ConfigurationManagement[0]?.club?.forEach(
            (element1) => {
              // console.log(element1);
              PreparedData.push({
                primaryId: element.Id,
                clubType: element1.clubType,
                configId: element1.configId,
                configName: element1.configName,
                friendlyName: element1.friendlyName,
                isDeleted: element1.isDeleted,
                manufacturer: element1.manufacturer,
                price: element1.price,
                sku: element1.sku,
                status: element1.status,
                Brand: element.Brand,
                configurationType: element.configurationType,
              });
            }
          );
        });

        //  console.log(PreparedData);

        setConfigList(PreparedData);
        setTimeout(() => {
          setSelectedConfig("1");
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBrands();
    getConfigData();
  }, []);

  var currentComponent;

  if (selectedConfig == "1") {
    currentComponent = (
      <GripsManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "2") {
    currentComponent = (
      <LoftManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "3") {
    currentComponent = (
      <LieManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "4") {
    currentComponent = (
      <FlexManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "5") {
    currentComponent = (
      <BounceManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "6") {
    currentComponent = (
      <GrindManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "7") {
    currentComponent = (
      <LoftAdjustment BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "8") {
    currentComponent = (
      <TypeManagement BrandList={BrandList} configList={ConfigList} />
    );
  } else if (selectedConfig == "9") {
    currentComponent = (
      <NeckManagement BrandList={BrandList} configList={ConfigList} />
    );
  }
  return Loading ? (
    <Box sx={{ display: "flex", height: "100vh", p: 2 }}>
      <Box sx={{ width: "200px", bgcolor: "#f5f5f5", p: 2 }}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{
                backgroundColor: "#F2F0F0",
                color: "#B20021",
                fontSize: 22,
              }}
            >
              Brands
            </ListSubheader>
          }
        >
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width="100%" height={50} />
          </ListItem>
        </List>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2, textAlign: "center" }}>
        <Skeleton variant="text" width="75%" height={30} />
        <Skeleton
          variant="rectangular"
          width="75%"
          height={50}
          sx={{ mt: 2 }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, p: 2, textAlign: "center" }}>
        <Skeleton variant="text" width="75%" height={30} />
        <Skeleton
          variant="rectangular"
          width="75%"
          height={50}
          sx={{ mt: 2 }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, p: 2, textAlign: "center" }}>
        <Skeleton variant="text" width="75%" height={30} />
        <Skeleton
          variant="rectangular"
          width="75%"
          height={50}
          sx={{ mt: 2 }}
        />
      </Box>
    </Box>
  ) : (
    <Box m="20px" height="75vh">
      <Grid container spacing={3} gridColumn={12}>
        <Grid item xs={12} sm={5} lg={5} xl={5}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={[
              { label: "Grips Management", value: "1" },
              { label: "Loft Management", value: "2" },
              { label: "Lie Adjustment", value: "3" },
              { label: "Flex Management", value: "4" },
              { label: "Bounce Management", value: "5" },
              { label: "Grind Management", value: "6" },
              { label: "Loft Adjustment", value: "7" },
              { label: "Type Management", value: "8" },
              { label: "Neck Management", value: "9" },
            ]}
            sx={{ width: 300 }}
            defaultValue={{ label: "Grips Management", value: "1" }}
            onChange={(event, newValue) => {
              setPageTitle(newValue.label);
              setSelectedConfig(newValue.value);
              console.log(newValue, selectedConfig);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Configuration" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h1" component="h2">
            {pageTitle}
          </Typography>
        </Grid>
      </Grid>
      {currentComponent}
    </Box>
  );
};

export default ConfigurationManagement;
