import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import {
  useTheme,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Avatar,
  Typography,
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";
import { useAuth } from "../../AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { AccountCircle } from "@mui/icons-material";
import LockResetTwoToneIcon from "@mui/icons-material/LockResetTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import MyProfile from "./MyProfile";
import ChangePassword from "../changepassword/index";

const Topbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [headerObj, setHeaderObj] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { setRender } = useAuth();
  const { render } = useAuth();
  const [rerender, setRerender] = useState(render);
  useEffect(() => {
    setRerender(render);
  }, [render]);

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
    setRender((prevCount) => prevCount + 1);
  };

  const { getUserDetails, logout } = useAuth();
  const data = getUserDetails();

  const handleChangePassword = () => {};

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("encryptToken");
    setAnchorEl(null);
    logout();
    navigate("/login");
  };
  const routeHeaderMapping = {
    Dashboard: { title: "DASHBOARD", subtitle: "Welcome To Dashboard" },
    "club-management": {
      title: "CLUB MANAGEMENT",
      subtitle: "Manage the club details",
    },
    "configuration-management": {
      title: "CONFIGURATION MANAGEMENT",
      subtitle: "Manage the configuration details",
    },
  };

  useEffect(() => {
    let homeRoute = location.pathname.split("/")[1];
    setHeaderObj(routeHeaderMapping[homeRoute] || {});
  }, [location.pathname]);

  return (
    // <Box display="flex" justifyContent="space-between" p={2}>
    //   <Box display="flex">
    //     {broken && !rtl && (
    //       <IconButton
    //         sx={{ margin: "0 6 0 2" }}
    //         onClick={() => toggleSidebar()}
    //       >
    //         <MenuOutlinedIcon />
    //       </IconButton>
    //     )}
    //   </Box>
    //   <Box display="flex">
    //     <IconButton>
    //       <NotificationsOutlinedIcon />
    //     </IconButton>
    //     <IconButton>
    //       <SettingsOutlinedIcon />
    //     </IconButton>
    //     <IconButton>
    //       <PersonOutlinedIcon />
    //     </IconButton>
    //     {broken && rtl && (
    //       <IconButton
    //         sx={{ margin: "0 6 0 2" }}
    //         onClick={() => toggleSidebar()}
    //       >
    //         <MenuOutlinedIcon />
    //       </IconButton>
    //     )}
    //   </Box>
    // </Box>

    <>
      {data ? (
        <Box display="flex" justifyContent="space-between" p={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box display="flex">
              {broken && !rtl && (
                <IconButton
                  sx={{ margin: "0 6 0 2" }}
                  onClick={() => toggleSidebar()}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ ml: 3 }}>
              <Header title={headerObj?.title} subtitle={headerObj?.subtitle} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            {/* <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton> */}
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  src={`${process.env.REACT_APP_IMG_URL}get/0/${data?.Id}/null/false/null`}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                sx={{ marginTop: 7 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenModal(!openModal);
                  }}
                >
                  <PersonOutlineTwoToneIcon sx={{ color: "#ad868d" }} />
                  <Typography
                    sx={{ ml: 1, color: "#666666", fontWeight: "bold" }}
                  >
                    My Profile
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenChangePassword(true);
                  }}
                >
                  <LockResetTwoToneIcon sx={{ color: "#ad868d" }} />
                  <Typography
                    sx={{ ml: 1, color: "#666666", fontWeight: "bold" }}
                  >
                    Change Password
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleLogut();
                  }}
                >
                  <LogoutTwoToneIcon sx={{ color: "#ad868d" }} />
                  <Typography
                    sx={{ ml: 1, color: "#666666", fontWeight: "bold" }}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
            {broken && rtl && (
              <IconButton
                sx={{ margin: "0 6 0 2" }}
                onClick={() => toggleSidebar()}
              >
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <MyProfile open={openModal} onClose={handleCloseModal} user={data} />
      <ChangePassword
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </>
  );
};

export default Topbar;
