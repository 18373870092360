import axios from "axios";
import API_ENDPOINTS from "../config/apiEndpoints";
import { authService } from "./auth.service";
import { useLocation } from "react-router-dom";
import { decryptObj, decryptToken, encryptObj } from "./global.service";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
});
export const axiosInstancePublic = axios.create({
  baseURL: API_ENDPOINTS.BASE_PUBLIC_URL,
});

export const axiosInstanceSecured = axios.create({
  baseURL: API_ENDPOINTS.BASE_PUBLIC_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = authService.getToken();
    if (token && authService.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      try {
        const response = await axios.post(
          `${API_ENDPOINTS.BASE_AUTH_URL}/${API_ENDPOINTS.REFRESH_TOKEN}`,
          {
            refreshToken: localStorage.getItem("refreshToken"),
            accessToken: localStorage.getItem("encryptToken"),
          }
        );
        const newAccessToken = response.data.result;
        localStorage.setItem("refreshToken", newAccessToken.refreshToken);
        localStorage.setItem("encryptToken", newAccessToken.accessToken);
        let tokenObj = await decryptToken();
        let customEncryption = encryptObj(tokenObj);
        localStorage.setItem("encryptToken", customEncryption);
        config.headers.Authorization = `Bearer ${newAccessToken.accessToken}`;
        return config;
      } catch (error) {
        console.error("Error refreshing token:", error);
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceSecured.interceptors.request.use(
  async (config) => {
    const token = authService.getToken();
    if (token && authService.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      try {
        const response = await axios.post(
          `${API_ENDPOINTS.BASE_AUTH_URL}/${API_ENDPOINTS.REFRESH_TOKEN}`,
          {
            refreshToken: localStorage.getItem("refreshToken"),
            accessToken: localStorage.getItem("encryptToken"),
          }
        );
        const newAccessToken = response.data.result;
        localStorage.setItem("refreshToken", newAccessToken.refreshToken);
        localStorage.setItem("encryptToken", newAccessToken.encryptedToken);
        config.headers.Authorization = `Bearer ${newAccessToken}`;
        return config;
      } catch (error) {
        console.error("Error refreshing token:", error);
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response) {
        // Request made and server responded

        // console.log(error.response.data);

        if (error.response.data) {
          return Promise.reject(error.response.data);
        } else {
          return Promise.reject(error.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request._response);
        return Promise.reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error ff", error);

        return Promise.reject(error);
      }
    } catch (error) {
      console.log("From API call ", error);
    }
  }
);

export default axiosInstance;
