import axiosInstance from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

export const GetConfigurationList = async (ConfigType, Brand, Club) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.CONFIG.GET_CONFIG}?configType=${ConfigType}&brandName=${Brand}&clubType=${Club}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const UpsertConfiguration = async (fromData) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CONFIG.UPSERT_CONFIG}`,
      fromData
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
