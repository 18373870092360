import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { BrandList } from "../../data/branddata";
//import { GripList } from "../../data/gripdata";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import {
  Typography,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { UpsertConfiguration } from "../../services/Configuration.Service";
import { GetConfigurationList } from "../../services/Configuration.Service";
import { GetSku, getExistingGripList } from "../../services/Club.Service";
import { toast } from "react-toastify";
import { connect } from "formik";
import { useConfirm } from "material-ui-confirm";
import { GridPanelFooter } from "@mui/x-data-grid";

const GripsManagement = ({ BrandList, configList }) => {
  let intialBrandId = null;
  const [selectedBrand, setSelectedBrand] = React.useState(intialBrandId);
  const [currentGripList, setGripList] = React.useState([]);
  const [InitialGripData, setInitialGripData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [GetDataLoad, setDataLoad] = useState(false);

  const [open, setOpen] = useState(false);
  const [Reload, setReload] = useState(false);
  const [RenderData, setRenderData] = useState([]);
  const [ValidationError, setValidationError] = useState([]);

  // params for sku
  const [SkuModelOpen, setSkuModelOpen] = React.useState(false);
  const [selectedSkuValue, setselectedSkuValue] = React.useState(null);
  const [currentConfigId, setcurrentConfigId] = React.useState(null);
  const [SKUsearchValue, setSKUsearchValue] = React.useState("");
  const [SkuSearchLoading, setSkuSearchLoading] = React.useState(false);
  const [SkuDataList, setSkuDataList] = React.useState([]);

  // params for Grip Search
  const [GripSearchModelOpen, setGripSearchModelOpen] = React.useState(false);
  const [selectedGripValue, setselectedGripValue] = React.useState(null);
  const [GripsearchValue, setGripsearchValue] = React.useState("");
  const [GripSearchLoading, setGripSearchLoading] = React.useState(false);
  const [SearchGripDataList, setSearchGripDataList] = React.useState([]);

  const confirm = useConfirm();

  const getConfigData = async () => {
    try {
      setDataLoad(true);
      var response = await GetConfigurationList("Grip", selectedBrand, "");
      if (response?.isSuccess == true) {
        response?.result.forEach((element) => {
          element.configJson = JSON.parse(element.configJson);
        });

        let PreparedData = [];

        response?.result.forEach((element) => {
          element.configJson?.ConfigurationManagement[0]?.club?.forEach(
            (element1) => {
              // console.log(element1);
              PreparedData.push({
                primaryId: element.Id,
                clubType: element1.clubType,
                configId: element1.configId,
                configName: element1.configName,
                friendlyName: element1.friendlyName,
                isDeleted: element1.isDeleted,
                manufacturer: element1.manufacturer,
                price: element1.price,
                sku: element1.sku,
                status: element1.status,
                Brand: element.Brand,
                configurationType: element.configurationType,
              });
            }
          );
        });
        setInitialGripData(PreparedData);
        setGripList(PreparedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoad(false);
    }
  };

  useEffect(() => {
    setSelectedBrand(
      BrandList && BrandList.length > 0 ? BrandList[0].Brand : null
    );
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      getConfigData();
    }
  }, [Reload, selectedBrand]);

  const handleListItemClick = async (event, index) => {
    var ComparedData = await compareData();
    if (ComparedData.length > 0) {
      confirm({
        description:
          "You have unsaved changes, are you sure you want to leave ?",
      })
        .then(() => {
          setSelectedBrand(index);
        })
        .catch(() => {});
    } else {
      setSelectedBrand(index);
    }
  };

  const findMaxValue = async () => {
    let maxId = 0;
    maxId = currentGripList
      ?.filter(
        (item) =>
          item.Brand.toLowerCase() == selectedBrand?.toLowerCase() &&
          item.configurationType == "Grip"
      )
      ?.reduce((max, obj) => (obj.configId > max ? obj.configId : max), 0);

    maxId = maxId + 1;

    console.log("The maximum ID is:", maxId);

    return maxId;
  };

  const addNewItem = async () => {
    var data = currentGripList?.find(
      (item) =>
        item.Brand == selectedBrand &&
        item.isDeleted == false &&
        item.configurationType == "Grip"
    );

    const newitem = {
      primaryId: data?.primaryId ? data.primaryId : 0,
      configId: await findMaxValue(),
      configName: "",
      friendlyName: "",
      manufacturer: "",
      Brand: selectedBrand,
      clubType: null,
      configurationType: "Grip",
      price: 0,
      sku: "",
      status: true,
      isDeleted: false,
    };
    const validationKey = `${newitem.Brand}_${newitem.configId}_${newitem.primaryId}_${newitem.configurationType}`;
    setValidationError((prevErrors) => [...prevErrors, validationKey]);

    currentGripList.push(newitem);
    setGripList(Array.from(currentGripList));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      var data = currentGripList?.filter(
        (item) =>
          item.Brand == selectedBrand && item.configurationType == "Grip"
      );
      let primaryId = 0;

      var consolidatedData = [];
      data.forEach((element) => {
        if (element.configName != "" && element.configName.trim().length >= 1) {
          primaryId = element.primaryId;
          consolidatedData.push({
            configId: element.configId,
            clubType: null,
            configName: element.configName,
            friendlyName: element.friendlyName,
            manufacturer: element.manufacturer,
            price: element.price,
            sku: element.sku,
            status: element.status,
            isDeleted: element.isDeleted,
            configurationType: element.configurationType,
          });
        }
      });

      console.log(consolidatedData);

      var finalData = {
        ConfigurationManagement: [
          {
            id: primaryId,
            brand: selectedBrand,
            configurationType: "Grip",
            club: consolidatedData,
          },
        ],
      };

      console.log(finalData);

      var RequestData = {
        Id: primaryId,
        Brand: selectedBrand,
        configurationType: "Grip",
        configMasters: JSON.stringify(finalData),
        clubType: "",
      };

      var response = await UpsertConfiguration(RequestData);

      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setReload(!Reload);
    }
  };

  const compareData = () => {
    let changes = [];

    InitialGripData.forEach((oldItem) => {
      const newItem = currentGripList.find(
        (item) => item.configId === oldItem.configId
      );
      if (newItem) {
        if (
          oldItem.configName !== newItem.configName ||
          oldItem.price !== newItem.price ||
          oldItem.isDeleted !== newItem.isDeleted ||
          oldItem.sku !== newItem.sku ||
          oldItem.status !== newItem.status
        ) {
          changes.push(oldItem.configId);
        }
      }
    });

    return changes;
  };

  const getValuesForSKUKey = async () => {
    setSkuSearchLoading(true);
    try {
      var response = await GetSku(SKUsearchValue);
      if (response.isSuccess == true) {
        setSkuDataList(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
    } finally {
      setSkuSearchLoading(false);
    }
  };

  const updateSKUValue = async () => {
    setGripList((prevItems) =>
      prevItems.map((item) =>
        item.configId === currentConfigId &&
        item.Brand.toLowerCase() == selectedBrand?.toLowerCase()
          ? { ...item, sku: selectedSkuValue }
          : item
      )
    );

    setselectedSkuValue(null);
    setcurrentConfigId(null);
    setSkuDataList([]);
    setSKUsearchValue("");
    setSkuModelOpen(false);
  };

  const getExistingGrip = async () => {
    setGripSearchLoading(true);
    try {
      var response = await getExistingGripList(GripsearchValue);
      if (response.isSuccess == true) {
        setSearchGripDataList(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
    } finally {
      setGripSearchLoading(false);
    }
  };

  const updateSearchGripValue = async () => {
    setGripList((prevItems) =>
      prevItems.map((item) =>
        item.configId === currentConfigId &&
        item.Brand.toLowerCase() == selectedBrand?.toLowerCase()
          ? { ...item, configName: selectedGripValue }
          : item
      )
    );

    setselectedGripValue(null);
    setcurrentConfigId(null);
    setSearchGripDataList([]);
    setGripsearchValue("");
    setGripSearchModelOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} marginTop={6} columns={12}>
        <Grid item xs={4} lg={3} xl={2}>
          <Paper
            elevation={0}
            sx={{ maxWidth: 256, backgroundColor: "#F2F0F0" }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    backgroundColor: "#F2F0F0",
                    color: "#B20021",
                    fontSize: 22,
                  }}
                >
                  Brands
                </ListSubheader>
              }
            >
              {BrandList &&
                BrandList.length > 0 &&
                BrandList.map((brandItem) => {
                  return (
                    <ListItemButton
                      key={brandItem?.Brand}
                      selected={selectedBrand === brandItem?.Brand}
                      onClick={(event) =>
                        handleListItemClick(event, brandItem?.Brand)
                      }
                    >
                      <ListItemText primary={brandItem?.Brand} />
                    </ListItemButton>
                  );
                })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={8} lg={9} xl={10}>
          <Stack direction="row" useFlexGap flexWrap="wrap">
            {currentGripList
              .filter(
                (item) =>
                  item?.Brand == selectedBrand &&
                  item.isDeleted == false &&
                  item.configurationType == "Grip"
              )
              .map((modelItem, index) => (
                <Card
                  sx={{
                    minWidth: 280,
                    maxWidth: 320,
                    marginBottom: "20px",
                    marginRight: 8,
                    backgroundColor: "#F2F0F0",
                    border: ValidationError.includes(
                      `${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}`
                    )
                      ? "1px solid red"
                      : "transparent",
                  }}
                  key={`${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}_${index}`}
                >
                  <CardContent>
                    <Badge
                      badgeContent={
                        <CancelIcon
                          onClick={() => {
                            confirm({
                              description:
                                "Do you want to delete this record ?",
                            })
                              .then(() => {
                                setGripList((prevItems) =>
                                  prevItems.map((item) =>
                                    item.configId === modelItem.configId &&
                                    item.Brand.toLowerCase() ==
                                      selectedBrand?.toLowerCase()
                                      ? { ...item, isDeleted: true }
                                      : item
                                  )
                                );
                                const validationKey = `${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}`;

                                setValidationError((prevErrors) =>
                                  prevErrors.filter(
                                    (error) => error !== validationKey
                                  )
                                );
                              })
                              .catch(() => {});
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                      }
                    >
                      <Grid container spacing={1} columns={12}>
                        <Grid container xs={12}>
                          <Tooltip title={modelItem.configName}>
                            <FormControl
                              fullWidth
                              sx={{ m: 1 }}
                              variant="standard"
                            >
                              <InputLabel htmlFor="standard-textname">
                                Name
                              </InputLabel>
                              <Input
                                autoComplete="off"
                                value={modelItem.configName}
                                startAdornment={
                                  <InputAdornment position="start"></InputAdornment>
                                }
                                onChange={(event) => {
                                  const validationKey = `${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}`;

                                  setGripList((prevItems) =>
                                    prevItems.map((item) =>
                                      item.configId === modelItem.configId &&
                                      item.Brand.toLowerCase() ==
                                        selectedBrand?.toLowerCase()
                                        ? {
                                            ...item,
                                            configName: event.target.value,
                                          }
                                        : item
                                    )
                                  );
                                  if (event.target.value.trim().length < 2) {
                                    setValidationError((prevErrors) =>
                                      prevErrors.filter(
                                        (error) => error !== validationKey
                                      )
                                    );
                                  }
                                }}
                                onBlur={(e) => {
                                  const validationKey = `${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}`;
                                  if (
                                    e.target.value == null ||
                                    e.target.value.trim() == ""
                                  ) {
                                    setValidationError((prevErrors) => [
                                      ...prevErrors,
                                      validationKey,
                                    ]);
                                  } else if (
                                    currentGripList.filter(
                                      (obj) =>
                                        obj.Brand === modelItem.Brand &&
                                        obj.configurationType ==
                                          modelItem.configurationType &&
                                        obj.configName.trim().toLowerCase() ==
                                          e.target.value.trim().toLowerCase()
                                    ).length > 1
                                  ) {
                                    setValidationError((prevErrors) => [
                                      ...prevErrors,
                                      validationKey,
                                    ]);
                                  } else {
                                    setValidationError((prevErrors) =>
                                      prevErrors.filter(
                                        (error) => error !== validationKey
                                      )
                                    );
                                  }
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ color: "primary.main" }}
                                      onClick={(e) => {
                                        setcurrentConfigId(modelItem.configId);
                                        setGripSearchModelOpen(true);
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {ValidationError.includes(
                                `${modelItem.Brand}_${modelItem.configId}_${modelItem.primaryId}_${modelItem.configurationType}`
                              ) ? (
                                modelItem.configName == null ||
                                modelItem.configName.trim() == "" ? (
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    color="primary"
                                  >
                                    Grip name is mandatroy
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    color="primary"
                                  >
                                    Grip name already exists
                                  </Typography>
                                )
                              ) : null}
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        <Grid container xs={6}>
                          <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                            variant="standard"
                          >
                            <InputLabel htmlFor="standard-adornment-amount">
                              Amount
                            </InputLabel>
                            <Input
                              value={modelItem.price}
                              type="number"
                              onKeyDown={(event) => {
                                const { key, target } = event;
                                const { value } = target;
                                const decimalCount = (value.match(/\./g) || [])
                                  .length;

                                // Allow navigation keys, backspace, and delete
                                if (
                                  key === "Backspace" ||
                                  key === "Delete" ||
                                  key === "ArrowLeft" ||
                                  key === "ArrowRight" ||
                                  key === "Tab" ||
                                  (key === "." && decimalCount === 0) // allow only one decimal point
                                ) {
                                  return;
                                }

                                // Prevent non-numeric characters
                                if (!/^\d$/.test(key)) {
                                  event.preventDefault();
                                }
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              }
                              onChange={(event) => {
                                setGripList((prevItems) =>
                                  prevItems.map((item) =>
                                    item.configId === modelItem.configId &&
                                    item.Brand.toLowerCase() ==
                                      selectedBrand?.toLowerCase()
                                      ? {
                                          ...item,
                                          price:
                                            event.target.value.trim().length > 0
                                              ? parseFloat(
                                                  event.target.value
                                                ).toString()
                                              : "",
                                        }
                                      : item
                                  )
                                );
                              }}
                              onBlur={(event) => {
                                setGripList((prevItems) =>
                                  prevItems.map((item) =>
                                    item.configId === modelItem.configId &&
                                    item.Brand.toLowerCase() ==
                                      selectedBrand?.toLowerCase()
                                      ? {
                                          ...item,
                                          price:
                                            event.target.value.trim().length > 0
                                              ? parseFloat(event.target.value)
                                                  .toFixed(2)
                                                  .toString()
                                              : "0.00",
                                        }
                                      : item
                                  )
                                );
                              }}
                              sx={{
                                "& input[type=number]": {
                                  MozAppearance: "textfield", // Hide arrows in Firefox
                                },
                                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                  {
                                    WebkitAppearance: "none", // Hide arrows in Chrome, Safari, Edge, and Opera
                                    margin: 0,
                                  },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid container xs={6}>
                          <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                            variant="standard"
                          >
                            <InputLabel htmlFor="standard-adornment-amount">
                              SKU
                            </InputLabel>
                            <Input
                              value={modelItem.sku}
                              autoComplete="off"
                              onChange={(e) => {
                                setGripList((prevItems) =>
                                  prevItems.map((item) =>
                                    item.configId === modelItem.configId &&
                                    item.Brand.toLowerCase() ==
                                      selectedBrand?.toLowerCase()
                                      ? {
                                          ...item,
                                          sku: e.target.value,
                                        }
                                      : item
                                  )
                                );
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ color: "primary.main" }}
                                    onClick={(e) => {
                                      setcurrentConfigId(modelItem.configId);
                                      setSkuModelOpen(true);
                                    }}
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Badge>
                  </CardContent>
                  <CardActions
                    sx={{
                      alignSelf: "stretch",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      // 👇 Edit padding to further adjust position
                      p: 0,
                    }}
                  >
                    {modelItem.status}
                    <Switch
                      defaultChecked={modelItem.status}
                      onChange={(event) => {
                        setGripList((prevItems) =>
                          prevItems.map((item) =>
                            item.configId === modelItem.configId &&
                            item.Brand.toLowerCase() ==
                              selectedBrand?.toLowerCase()
                              ? {
                                  ...item,
                                  status: event.target.checked,
                                }
                              : item
                          )
                        );
                      }}
                    />
                  </CardActions>
                </Card>
              ))}

            {GetDataLoad && (
              <Typography
                variant="h2"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Loading...
              </Typography>
            )}

            {!GetDataLoad &&
            selectedBrand != null &&
            currentGripList.filter(
              (item) => item.Brand === selectedBrand && item.isDeleted == false
            ).length <= 0 ? (
              <Typography
                variant="h3"
                component="h3"
                sx={{ textAlign: "center" }}
              >
                No records found. Click 'Add New' to create a new one.
              </Typography>
            ) : null}
          </Stack>

          {!GetDataLoad && selectedBrand != null ? (
            <Box sx={{ alignSelf: "flex-end" }}>
              <Button
                variant="contained"
                tabIndex={-1}
                sx={{ marginBottom: 5 }}
                startIcon={<AddCircleTwoToneIcon />}
                onClick={addNewItem}
              >
                Add New
              </Button>
            </Box>
          ) : null}
          {!GetDataLoad && selectedBrand != null ? (
            <Box
              sx={{
                marginTop: 10,
                marginLeft: 20,
                marginRight: 20,
                paddingBottom: 10,
              }}
            >
              <Button
                sx={{ width: { sm: "100%" } }}
                component="label"
                size="large"
                variant="contained"
                tabIndex={-1}
                disabled={ValidationError.length > 0 ? true : false}
                startIcon={<SaveTwoToneIcon />}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                ) : (
                  "Commit Changes"
                )}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Grid>

      <Dialog
        open={SkuModelOpen}
        maxWidth="sm"
        fullWidth
        sx={{
          zIndex: 10001,
          maxHeight: 500,
          minHeight: 500,
          overflowY: "scroll",
        }}
      >
        <DialogContent>
          <TextField
            autoComplete="off"
            sx={{ width: "100%", marginBottom: 2 }}
            label="SKU keyword"
            variant="standard"
            value={SKUsearchValue}
            onChange={(e) => setSKUsearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "primary.main" }}
                    disabled={SKUsearchValue.length >= 1 ? false : true}
                    onClick={(e) => getValuesForSKUKey()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {SkuSearchLoading ? (
            <CircularProgress size={24} sx={{ marginBottom: 2 }} />
          ) : (
            SkuDataList.map((element) => {
              return (
                <ListItemButton
                  key={element?.productId}
                  selected={selectedSkuValue === element?.productId}
                  onClick={(event) => setselectedSkuValue(element?.productId)}
                >
                  <ListItemText
                    primary={element?.productName}
                    secondary={element?.productId}
                  />
                </ListItemButton>
              );
            })
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={(e) => updateSKUValue()}
            disabled={selectedSkuValue == null ? true : false}
          >
            Update SKU
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => {
              setselectedSkuValue(null);
              setcurrentConfigId(null);
              setSkuDataList([]);
              setSKUsearchValue("");
              setSkuModelOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={GripSearchModelOpen}
        maxWidth="sm"
        fullWidth
        sx={{
          zIndex: 10001,
          maxHeight: 500,
          minHeight: 500,
          overflowY: "scroll",
        }}
      >
        <DialogContent>
          <TextField
            sx={{ width: "100%", marginBottom: 2 }}
            label="Grip Name"
            variant="standard"
            value={GripsearchValue}
            onChange={(e) => setGripsearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "primary.main" }}
                    disabled={GripsearchValue.length > 2 ? false : true}
                    onClick={(e) => getExistingGrip()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {GripSearchLoading ? (
            <CircularProgress size={24} sx={{ marginBottom: 2 }} />
          ) : (
            SearchGripDataList.map((element) => {
              return (
                <ListItemButton
                  key={element?.productId}
                  selected={selectedGripValue === element}
                  onClick={(event) => setselectedGripValue(element)}
                >
                  <ListItemText primary={element} />
                </ListItemButton>
              );
            })
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={(e) => updateSearchGripValue()}
            disabled={selectedGripValue == null ? true : false}
          >
            Update Grip
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => {
              setselectedGripValue(null);
              setcurrentConfigId(null);
              setSearchGripDataList([]);
              setSKUsearchValue("");
              setGripSearchModelOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default GripsManagement;
