import React from "react";
import {
  Route,
  Navigate,
  RouteProps,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./AuthContext";
import { authService } from "./services/auth.service";

// const authRoute = (url, getUserDetails, menuList) => {
//   let restrict = true;
//   let homeRoute = "/" + url.split("/")[1];
//   if (homeRoute == "/") return "adminDashboard";
//   menuList.forEach((each) => {
//     if (each.url == homeRoute) {
//       restrict = false;
//     } else if (url == each.url) {
//       restrict = false;
//     }
//   });
//   return restrict;
// };

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, getUserDetails } = useAuth();
  const data = getUserDetails();

  const token = localStorage.getItem("token");

  if (!authService.isAuthenticated()) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};
export default PrivateRoute;
