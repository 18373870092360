import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  MonthView,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  DragDropProvider, 
  WeekView,
  Toolbar,
  ViewSwitcher,
  DayView,DateNavigator,TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,Container
} from "@mui/material";


import { tokens } from "../../theme";
import Header from "../../components/Header";
import { appointments } from '../../data/calender/month-appointments';

export default class ViewSwitching extends React.PureComponent {

    constructor(props) {
        super(props);
    
        this.state = {
          data: appointments,
          currentViewName: 'work-week',
        };
        this.currentViewNameChange = (currentViewName) => {
          this.setState({ currentViewName });
        };
      }
  render() {
    
    const { data, currentViewName } = this.state;
  
    return (
      <Box m="20px">
      <Header title="Calendar" subtitle="Full Calendar Interactive Page" />
      <Grid container spacing={2} className="grid-appointment">
        <Grid xs={12} >
       <Box  style={{ border: "1px solid #f2f0f0"}}>
       <Scheduler
          data={data}
          height="75vh"
          
        >
          <ViewState
            defaultCurrentDate="2018-07-25"
            currentViewName={currentViewName}
            onCurrentViewNameChange={this.currentViewNameChange}
          />

          <WeekView
            startDayHour={10}
            endDayHour={19}
          />
          <WeekView
            name="work-week"
            displayName="Work Week"
            excludedDays={[0, 6]}
            startDayHour={9}
            endDayHour={19}
          />
          <MonthView />
          <DayView />

          <Toolbar  />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <Appointments  />
        </Scheduler>
       </Box>
    
       
          </Grid>
        </Grid>
      </Box>
      
    );
  }
}
