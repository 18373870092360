import moment from "moment";
import { decryptObj } from "./global.service";

class AuthService {
  isAuthenticated() {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      const jwtToken = decryptObj(token);
      const utcSeconds = jwtToken.exp;
      const now = moment().unix();

      if (utcSeconds === null || utcSeconds < now) {
        this.logout();
        console.log("Invalid token || Token expired");
        return false;
      }

      return true;
    } catch (error) {
      console.log("Auth error", error);
      return false;
    }
  }

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  }

  getToken() {
    return localStorage.getItem("encryptToken") || null;
  }
}

export const authService = new AuthService();
