import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";

import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Invoices from "./pages/invoices";
import Contacts from "./pages/contacts";
import Form from "./pages/form";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import StoreExperts from "./pages/calendar/storeExperts";
import ViewSwitchings from "./pages/calendar/viewSwitching";
import GroupAppointments from "./pages/calendar/groupAppointments";
import StoreAppointments from "./pages/calendar/storesAppointments";
import ClubManagement from "./pages/club-management";
import ClubManagementImport from "./pages/club-management/import-data";
import ConfigurationManagement from "./pages/configuration-management";
import PrivateRoute from "./PrivateRoutes";
import { ToastContainer } from "react-toastify";
import "./App.css";

import Login from "./pages/login/Login";
import { AuthProvider } from "./AuthContext";
import { authService } from "./services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmProvider } from "material-ui-confirm";
const PageNotFound = () => {
  return (
    <Typography
      sx={{
        display: "flex",
        width: "100%",
        height: "60vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      variant="h1"
      align="center"
    >
      404 - Page Not Found
    </Typography>
  );
};

const App = () => {
  const [theme, colorMode] = useMode();

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
  }

  return (
    <AuthProvider>
      <ConfirmProvider
        defaultOptions={{
          allowClose: false,
          confirmationButtonProps: { variant: "contained", color: "error" },
          cancellationButtonProps: { variant: "text", color: "inherit" },
          dialogProps: { sx: { zIndex: 10002 } },

          title: "",
        }}
      >
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password/:resetToken" element={<Login />} />
            </Routes>

            <MyProSidebarProvider>
              <div style={{ height: "100%", width: "100%" }}>
                <main>
                  <Topbar />
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/Dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route
                      path="/club-management"
                      element={
                        <PrivateRoute>
                          <ClubManagement />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/configuration-management"
                      element={
                        <PrivateRoute>
                          <ConfigurationManagement />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/club-management/import"
                      element={<ClubManagementImport />}
                    />
                    <Route
                      path="/calendar/storeexperts"
                      element={<StoreExperts />}
                    />
                    <Route
                      path="/calendar/viewSwitchings"
                      element={<ViewSwitchings />}
                    />
                    <Route
                      path="/calendar/groupappointments"
                      element={<GroupAppointments />}
                    />
                    <Route
                      path="/calendar/storeappointments"
                      element={<StoreAppointments />}
                    />
                  </Routes>
                </main>
              </div>
            </MyProSidebarProvider>

            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </ConfirmProvider>
    </AuthProvider>
  );
};

export default App;
