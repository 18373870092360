import { createContext, useState, useMemo } from "react";

import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        primaryV2: {
          50: "#feeaee",
          100: "#fccbd3",
          200: "#eb969b",
          300: "#e06d75",
          400: "#ea4954",
          500: "#ef333b",
          600: "#e0293a",
          700: "#ce1e33",
          800: "#c1152c",
          900: "#b20021",
        },
        greenAccent: {
          50: "#e6f5ed",
          100: "#c2e5d2",
          200: "#9bd4b6",
          300: "#72c59a",
          400: "#52b885",
          500: "#2dac71",
          600: "#279d66",
          700: "#208b59",
          800: "#1b7a4d",
          900: "#155a39",
        },
        redAccent: {
          50: "#feeaee",
          100: "#fccbd3",
          200: "#eb969b",
          300: "#e06d75",
          400: "#ea4954",
          500: "#ef333b",
          600: "#e0293a",
          700: "#ce1e33",
          800: "#c1152c",
          900: "#b20021",
        },
        blueAccent: {
          50: "#e7e8f4",
          100: "#c3c6e4",
          200: "#9ca1d2",
          300: "#757dc0",
          400: "#5961b3",
          500: "#3d46a6",
          600: "#373e9d",
          700: "#2f3591",
          800: "#272b85",
          900: "#191970",
        },
        agColors: {
          black: "#000000",
          "grey-dark": "#121C26",
          grey: "#666666",
          "grey-lighter": "#CFC9C4",
          "grey-light": "#F5F4F3",
          white: "#FFFFFF",
          blue: "#191970",
          "blue-lighter": "#3B3B85",
          red: "#DC143C",
          "red-darker": "#B20021",
          orange: "#F4963C",
          "orange-darker": "#EE8016",
          green: "#2DAC70",
          peach: "#f2f0f0",
          txtRed: "#ad868d",
        },
        tabelColors: {
          "header-bg": "#f2f0f0",
          "body-bg": "#ffffff",
          "footer-bg": "#f2f0f0",
          "checkbox-color": "#B20021 !important",
          boder: "1px solid #f2f0f0",
          "cell-highlight-color": "#191970",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primaryV2[900],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["agFont", "sans-serif"].join(","),
      fontSize: 13,
      h1: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["agFont", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
