import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { GetUserProfile } from "../../services/preferecne.service";
import { updateUser } from "../../services/users.services";
import axiosInstance from "../../services/axiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../../theme";
import { ClearIcon } from "@mui/x-date-pickers";
import apiEndpoints from "../../config/apiEndpoints";

const MyProfile = ({ open, onClose, user }) => {
  const [userData, setUserData] = useState({});
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [image, setImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [preview, setPreview] = useState(null); // Preview image URL
  const fileInputRef = useRef(null); // Reference to the file input
  const [loading, setLoading] = useState(false);
  const successMessageRef = useRef(null);

  const getMyProfileData = async () => {
    try {
      const response = await GetUserProfile(user?.Id);
      if (response?.isSuccess) {
        setUserData(response?.result);
        setFname(response?.result?.FirstName);
        setLname(response?.result?.LastName);
        setPreview(
          `${apiEndpoints.BASE_PUBLIC_URL.split("/api")[0]}/get/${0}/${
            user?.UserId
          }/${null}/${false}/${null}`
        );
      } else {
        setUserData({});
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  function base64ToFile(base64String, filename) {
    // Decode base64 string
    const byteString = atob(base64String.split(",")[1]);

    // Create an ArrayBuffer and a view
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer
    const blob = new Blob([ab], { type: "image/png" });

    // Create a File from the Blob
    const file = new File([blob], filename, {
      type: "image/png",
      lastModified: new Date().getTime(),
    });

    return file;
  }
  const fetchImageEdit = async () => {
    try {
      const response = await axiosInstance.get(
        `${apiEndpoints.BASE_PUBLIC_URL.split("/api")[0]}/get/${0}/${
          user?.UserId
        }/${null}/${false}/${null}`,
        { responseType: "arraybuffer" }
      );

      const base64String = arrayBufferToBase64(response.data);
      const base64Image = `data:image/png;base64,${base64String}`;
      setImage(base64ToFile(base64Image, "ImageFile"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const updateUserProfile = async () => {
    setSuccessMessage("");
    setLoading(true);
    const newUser = {
      UserId: user.Id,
      EmailId: userData.EmailId,
      FirstName: fname,
      LastName: lname,
      Password: "Qwerty123", // Make sure you handle password securely
      Role: userData.RoleId,
      // Store: userData.store,
      // ReportingTo: userData.reportingTo,
      Status: userData.Status,
      Profile: "",
      ImageFile: image,
    };

    const formData = new FormData();
    Object.entries(newUser).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await updateUser(formData);
      if (response.isSuccess) {
        setLoading(false);
        setSuccessMessage("Profile updated successfully!");
        successMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        //onClose(); // Close the dialog only on success
      } else {
        setLoading(false);
        setSuccessMessage("Profile Not Updated!");
        successMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        console.log("Error adding/updating user:", response.message);
      }
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error adding/updating user:", error);
      successMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      // Keep the dialog open on exception
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getMyProfileData();
      fetchImageEdit();
    }
  }, [user]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      open={open}
      maxWidth="md" // Change the value according to your needs (e.g., "sm", "md", "lg", etc.)
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          console.log("Closed");
        }
      }}
      sx={{
        "& .MuiDialog-paper": {
          padding: 2,
          borderRadius: 3,
          backgroundColor: "#f2f0f0",
          margin: "auto", // Ensures the dialog is centered
        },
      }}
    >
      <DialogTitle
        fontWeight="bold"
        variant="h4"
        textAlign="center"
        sx={{
          backgroundColor: "#f2f0f0",
          color: "#666666",
          padding: 2,
          borderRadius: 1,
          marginBottom: 3,
        }}
      >
        My Profile
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
            // const url = new URL(window.location.href);
            // url.searchParams.set('cache_bust', new Date().getTime().toString());
            // window.location.href = url.toString();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#B20021",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={3}
          onClick={handleImageClick}
          sx={{ cursor: "pointer" }}
        >
          <Avatar
            src={preview}
            alt="User Image"
            sx={{
              width: 100,
              height: 100,
              border: "3px solid darkgrey",
            }}
          />
        </Box>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#555",
                display: "flex",
                alignItems: "center",
              }}
            >
              Store Name:
              <Typography sx={{ ml: 1 }}>
                {userData?.store || "Not Applicable"}
              </Typography>
            </Typography>
            {/* <TextField
              name="store"
              value={userData?.store || "Not Applicable"}
              fullWidth
              disabled
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#555",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Email :
              <Typography sx={{ ml: 1 }}>{userData?.EmailId || "-"}</Typography>
            </Typography>
            {/* <TextField
              name="EmailId"
              value={userData?.EmailId}
              fullWidth
              disabled
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#555",
                display: "flex",
                alignItems: "center",
              }}
            >
              Organization Role:
              <Typography sx={{ ml: 1 }}>{userData?.Role || "-"}</Typography>
            </Typography>
            {/* <TextField
              name="role"
              value={userData?.Role}
              fullWidth
              disabled
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#555",
                display: "flex",
                alignItems: "center",
              }}
            >
              Reporting To:
              <Typography sx={{ ml: 1 }}>
                {user?.reportingToId && user?.reportingTo
                  ? `${userData?.reportingTo}`
                  : "Not Applicable"}
              </Typography>
            </Typography>
            {/* <TextField
              name="reportingTo"
              value={
                user?.reportingToId && user?.reportingTo
                  ? `${userData?.reportingTo}`
                  : "Not Applicable"
              }
              fullWidth
              disabled
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#555",
                display: "flex",
                alignItems: "center",
              }}
            >
              Status:
              <Typography sx={{ ml: 1 }}>
                {userData?.Status ? "Active" : "Inactive"}
              </Typography>
            </Typography>
            {/* <TextField
              name="status"
              value={userData?.Status ? "Active" : "Inactive"}
              fullWidth
              disabled
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              First Name:
            </Typography>
            <TextField
              name="fname"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              fullWidth
              // sx={{
              //   "& .Mui-disabled": {
              //     WebkitTextFillColor: "grey !important",
              //   },
              //   "& .MuiInputBase-root": {
              //     backgroundColor: "#fff",
              //     borderRadius: 1,
              //     padding: 0.5,
              //   },
              // }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              Last Name:
            </Typography>
            <TextField
              name="lname"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              fullWidth
              // InputProps={{
              //   endAdornment: lname.length>0 && (
              //     <InputAdornment position="end">
              //       <IconButton
              //         size="small"
              //         onClick={() => setLname("")}
              //       >
              //         <ClearIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
              // sx={{
              //   "& .Mui-disabled": {
              //     WebkitTextFillColor: "grey !important",
              //   },
              //   "& .MuiInputBase-root": {
              //     backgroundColor: "#fff",
              //     borderRadius: 1,
              //     padding: 0.5,
              //   },
              // }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            {successMessage.trim() !== "" && (
              <Typography
                sx={{
                  color: "#B20021",
                  textAlign: "center",
                  mt: 1,
                  // fontSize: "1rem",
                }}
                variant="subtitle2"
                gutterBottom
              >
                {successMessage}
              </Typography>
            )}
          </Box>
          <Box sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: colors.agColors["red-darker"],
                borderColor: colors.agColors["red-darker"],
                color: colors.agColors["white"],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={updateUserProfile}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
        {/* <Button
                    variant="outlined"
                    
                    sx={{ backgroundColor: '#fff', '&:hover': { backgroundColor: '#fff',color:'#B2002' } }}
                    onClick={() => {
                        onClose();
                        const url = new URL(window.location.href); // Use window.location.href instead of window.location
                        url.searchParams.set('cache_bust', new Date().getTime().toString());
                        window.location.href = url.toString();
                    }}
                >
                    Cancel
                </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default MyProfile;

// const MyProfile = ({ open, onClose, user }: any) => {
//     const [userData, setUserData] = useState<any>({});
//     const [fname,fsetname]=useState('')
//     const [lname,setlname]=useState('')
//     const [image,setImage]=useState<any>(null)
//     console.log(image,"image")

//     const getMyProfileData = async () => {
//         try {
//             const response = await GetUserProfile(user?.Id);
//             if (response?.isSuccess) {
//                 setUserData(response?.result);
//                 fsetname(response?.result?.FirstName)
//                 setlname(response?.result?.LastName)
//             } else {
//                 setUserData({});
//             }
//         } catch (error) {
//             console.error("Error fetching user data:", error);
//         }
//     };
//     const updateUserProfile = async (newUser: any) => {
//         console.log("newuser",newUser)
//         const file = newUser.ImageFile;
//         let data = { ...newUser };
//         const formData = new FormData();

//         if (file) {
//           formData.append("imageFile", file);
//         }

//         if (typeof data.password !== "string") {
//           data.password = String(data.password);
//         }

//         Object.entries(data).forEach(([key, value]: any) => {
//           formData.append(key, value);
//         });

//         try {
//           let response;
//             response = await updateUser(formData);
//           if (response.isSuccess) {
//             console.log(response)
//             // Close the dialog only on success
//           } else {
//             console.log("Error adding/updating user:", response.message);
//           }
//         } catch (error) {
//           console.error("Error adding/updating user:", error);
//           // Keep the dialog open on exception
//         }
//       };
//     useEffect(() => {
//          if(user){
//             getMyProfileData();
//          }
//     }, [user]);

//     return (
//         <Dialog
//             open={open}
//             fullWidth
//             maxWidth="md"
//             onClose={(event, reason) => {
//                 if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
//                     console.log("Closed");
//                 }
//             }}
//             sx={{
//                 '& .MuiDialog-paper': {
//                     padding: 2,
//                     borderRadius: 3,
//                     backgroundColor: '#f2f0f0',
//                 },
//             }}
//         >
//                  <DialogTitle
//                 fontWeight="bold"
//                 variant="h4"
//                 textAlign="center"
//                 sx={{
//                     backgroundColor: '#f2f0f0',
//                     color: '#666666',
//                     padding: 2,
//                     borderRadius: 1,

//                     marginBottom: 3,
//                 }}
//             >
//                 My Profile
//             </DialogTitle>
//             <DialogContent>
//                 <Box
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="center"
//                     mb={3}
//                 >
//                     <Avatar
//                         src={`${import.meta.env.VITE_BASE_URL.split("/api")[0]}/get/${0}/${user?.UserId}/${null}/${false}/${null}`}
//                         alt="User Image"
//                         sx={{
//                             width: 100,
//                             height: 100,
//                             border: '3px solid #darkgrey',
//                         }}
//                     />
//                 </Box>
//                 <Grid container spacing={3}>
//                     <Grid item xs={12}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Store Name:
//                         </Typography>
//                         <TextField
//                             name="store"
//                             value={userData?.store || 'NA'}
//                             fullWidth
//                             disabled
//                             sx={{
//                                 mb: 2,
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             First Name:
//                         </Typography>
//                         <TextField
//                             name="fname"
//                             value={fname}
//                             onChange={(e)=>fsetname(e.target.value)}
//                             fullWidth
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Last Name:
//                         </Typography>
//                         <TextField
//                             name="lname"
//                             value={lname}
//                             fullWidth
//                             onChange={(e)=>setlname(e.target.value)}
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Email:
//                         </Typography>
//                         <TextField
//                             name="EmailId"
//                             value={userData?.EmailId}
//                             fullWidth
//                             disabled
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Organization Role:
//                         </Typography>
//                         <TextField
//                             name="role"
//                             value={userData?.Role}
//                             fullWidth
//                             disabled
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Reporting To:
//                         </Typography>
//                         <TextField
//                             name="reportingTo"
//                             value={
//                                 user?.reportingToId && user?.reportingTo
//                                     ? `${userData?.reportingTo}`
//                                     : ""
//                             }
//                             fullWidth
//                             disabled
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                         <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#555' }}>
//                             Status:
//                         </Typography>
//                         <TextField
//                             name="status"
//                             value={userData?.Status ? "Active" : "Inactive"}
//                             fullWidth
//                             disabled
//                             sx={{
//                                 '& .Mui-disabled': {
//                                     WebkitTextFillColor: 'grey !important',
//                                 },
//                                 '& .MuiInputBase-root': {
//                                     backgroundColor: '#fff',
//                                     borderRadius: 1,
//                                     padding: 0.5,
//                                 },
//                             }}
//                         />
//                     </Grid>
//                 </Grid>
//             </DialogContent>
//             <DialogActions>
//             <Button onClick={()=>updateUserProfile({
//                 UserId:'',
//                 EmailId:'',
//                 FirstName:'',
//                 LastName:'',
//                 Password:'',
//                 Role:'',
//                 Store:'',
//                 ReportingTo:'',
//                 Status:false,
//                 Profile:'',
//                 ImageFile:''

//             })} variant="contained" color="primary">
//                     Update
//                 </Button>
//                 <Button onClick={onClose} variant="contained" color="primary">
//                     Close
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default MyProfile;
