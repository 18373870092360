import { ReactNode, useEffect, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import CategoryIcon from "@mui/icons-material/Category";

const Dashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const [storesData, setStoresData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");
  const [months, setMonths] = useState(3);
  const [orderMonths, setOrderMonths] = useState(3);
  const [storeOrderMonths, setStoreOrderMonths] = useState(6);
  const [loading, setLoading] = useState(false);
  const [apppointemntsData, setAppointmentsData] = useState([]);
  const [orderSummaryData, setOrderSummaryData] = useState([]);
  const [orderSummaryStoresData, setSummaryStoresData] = useState([]);
  const [recentTranscationData, setRecentTransactionData] = useState([]);
  const [expert, setExpert] = useState("All");
  const [expertDropDownList, setExpertDropDownList] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [revenueLoading, setRevenueLoading] = useState(false); //
  const [AppointmentLoading, setAppointmentLoading] = useState(false);
  const [orderSummaryLoading, setOrderSummaryLoading] = useState(false);
  const [recentTransLoading, setRecentTransLoading] = useState(false);

  useEffect(() => {
    setTotalRevenue(
      orderSummaryStoresData?.reduce((acc, curr) => {
        acc = acc + curr.orderValue;
        return acc;
      }, 0)
    );
  }, [orderSummaryStoresData]);

  return (
    <Box m="20px">
      {/* HEADER */}

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="8"
              subtitle="Total Brands"
              // progress="0.75"
              increase="25"
              icon={
                <CategoryIcon
                  sx={{ color: colors.agColors["blue"], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="431,225"
              subtitle="Sales Obtained"
              progress="0.50"
              increase="+21%"
              icon={
                <PointOfSaleIcon
                  sx={{ color: colors.agColors["blue"], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="32,441"
              subtitle="New Clients"
              progress="0.30"
              increase="+5%"
              icon={
                <PersonAddIcon
                  sx={{ color: colors.agColors["blue"], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="1,325,134"
              subtitle="Traffic Received"
              progress="0.80"
              increase="+43%"
              icon={
                <TrafficIcon
                  sx={{ color: colors.agColors["blue"], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="20px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mt="25px">
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Revenue Generated
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={colors.agColors["red-darker"]}
                  >
                    $58,373,698
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storeOrderMonths}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        padding: 1, // Adjust the padding as needed
                      },
                      backgroundColor: "#F2F0F0 !important",
                      color: "black !important",
                    }}
                    onChange={(e) => {
                      let num = e.target.value;
                      setStoreOrderMonths(num);
                    }}
                  >
                    <MenuItem value={3}>Last 3 months</MenuItem>
                    <MenuItem value={6}>Last 6 months</MenuItem>
                    <MenuItem value={9}>Last 9 months</MenuItem>
                    <MenuItem value={12}>Last 12 months</MenuItem>
                  </Select>
                </Box>
                {/* <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{
                        fontSize: "26px",
                        color: colors.agColors["red-darker"],
                      }}
                    />
                  </IconButton>
                </Box> */}
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="20px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" fontWeight="600">
                  Campaign
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storeOrderMonths}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        padding: 1, // Adjust the padding as needed
                      },
                      backgroundColor: "#F2F0F0 !important",
                      color: "black !important",
                    }}
                    onChange={(e) => {
                      let num = e.target.value;
                      setStoreOrderMonths(num);
                    }}
                  >
                    <MenuItem value={3}>Last 3 months</MenuItem>
                    <MenuItem value={6}>Last 6 months</MenuItem>
                    <MenuItem value={9}>Last 9 months</MenuItem>
                    <MenuItem value={12}>Last 12 months</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <ProgressCircle size="125" />
                <Typography
                  variant="h5"
                  color={colors.agColors["red-darker"]}
                  sx={{ mt: "15px" }}
                >
                  $48,352 revenue generated
                </Typography>
                <Typography>
                  Includes extra misc expenditures and costs
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="20px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" fontWeight="600">
                  Sales Quantity
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storeOrderMonths}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        padding: 1, // Adjust the padding as needed
                      },
                      backgroundColor: "#F2F0F0 !important",
                      color: "black !important",
                    }}
                    onChange={(e) => {
                      let num = e.target.value;
                      setStoreOrderMonths(num);
                    }}
                  >
                    <MenuItem value={3}>Last 3 months</MenuItem>
                    <MenuItem value={6}>Last 6 months</MenuItem>
                    <MenuItem value={9}>Last 9 months</MenuItem>
                    <MenuItem value={12}>Last 12 months</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box height="250px" mt="-20px">
                <BarChart isDashboard={true} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="20px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  sx={{ marginBottom: "15px" }}
                >
                  Geography Based Traffic
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storeOrderMonths}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        padding: 1, // Adjust the padding as needed
                      },
                      backgroundColor: "#F2F0F0 !important",
                      color: "black !important",
                    }}
                    onChange={(e) => {
                      let num = e.target.value;
                      setStoreOrderMonths(num);
                    }}
                  >
                    <MenuItem value={3}>Last 3 months</MenuItem>
                    <MenuItem value={6}>Last 6 months</MenuItem>
                    <MenuItem value={9}>Last 9 months</MenuItem>
                    <MenuItem value={12}>Last 12 months</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box height="200px">
                <GeographyChart isDashboard={true} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            backgroundColor={colors.primary[400]}
            maxHeight="100vh"
            overflow="auto"
            m="20px 0 0 0"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`2px solid ${colors.agColors["grey-lighter"]}`}
              color={colors.grey[100]}
              p="15px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Recent Transaction
              </Typography>

              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={storeOrderMonths}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiSelect-select": {
                      padding: 1, // Adjust the padding as needed
                    },
                    backgroundColor: "#F2F0F0 !important",
                    color: "black !important",
                  }}
                  onChange={(e) => {
                    let num = e.target.value;
                  }}
                >
                  <MenuItem value={3}>Last 3 months</MenuItem>
                  <MenuItem value={6}>Last 6 months</MenuItem>
                  <MenuItem value={9}>Last 9 months</MenuItem>
                  <MenuItem value={12}>Last 12 months</MenuItem>
                </Select>
              </Box>
            </Box>
            {mockTransactions.map((transaction, i) => {
              return (
                <Box
                  key={`${transaction}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`1px solid ${colors.agColors["grey-lighter"]}`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.agColors["txtRed"]}
                    >
                      {transaction.txId}
                    </Typography>
                    <Typography
                      color={colors.grey[100]}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {transaction.user}
                    </Typography>
                  </Box>
                  <Box textAlign="center" color={colors.grey[700]}>
                    {transaction.date}
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: colors.agColors["grey-light"],
                      color: colors.agColors.txtRed,
                    }}
                    border={`1px solid #cfc9c4`}
                    p="5px 10px"
                    borderRadius="4px"
                    textAlign="right"
                  >
                    ${transaction.cost}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
