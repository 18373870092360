import axiosInstance from "./axiosInstance";
import { AxiosResponse } from "axios";
import API_ENDPOINTS from "../config/apiEndpoints";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SECRET_KEY;
const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;

export const getManagerExpertStoreList = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.STORES.DROPDOWN}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};
export const getServiceTypes = async (data) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.SERVICETYPES.SERVICE_TYPES,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const decryptToken = async () => {
  try {
    const encryptedData = localStorage.getItem("encryptToken");
    console.log("encryptedData ---->", encryptedData);
    if (!encryptedData) return null;
    const formatBase64 = (b64String) => {
      let output = b64String.replace(/-/g, "+").replace(/_/g, "/");
      while (output.length % 4 !== 0) {
        output += "=";
      }
      return output;
    };

    const safeBase64 = formatBase64(encryptedData);
    const decodedData = window.atob(safeBase64);
    const encryptedBuffer = new Uint8Array(
      Array.from(decodedData).map((char) => char.charCodeAt(0))
    );
    const iv = encryptedBuffer.slice(0, 16);
    const encryptedToken = encryptedBuffer.slice(16);

    const algo = { name: "AES-CBC", iv: iv };
    const encodedKey = new TextEncoder().encode(secretKey.toString());
    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      encodedKey,
      algo,
      false,
      ["decrypt"]
    );

    const decrypted = await window.crypto.subtle.decrypt(
      algo,
      cryptoKey,
      encryptedToken
    );
    const decryptedString = new TextDecoder().decode(decrypted);

    const decodeToken = jwtDecode(decryptedString);
    const sub = JSON.parse(decodeToken.sub);
    const { nbf, exp, iat, iss } = decodeToken;
    let tokenObj = {
      ...sub,
      role: sub.Role,
      UserId: sub.Id,
      nbf,
      exp,
      iat,
      iss,
    };

    const roleIdMapping = {
      Admin: 1,
      "Regional Manager": 2,
      Manager: 3,
      Expert: 4,
    };
    tokenObj.roleId = roleIdMapping[tokenObj.role];
    return tokenObj;
  } catch (error) {
    console.error("Decryption failed:", error);
    throw error;
  }
};

export const encryptObj = (obj) => {
  const jsonString = JSON.stringify(obj);
  const encrypted = CryptoJS.AES.encrypt(
    jsonString,
    encryptKey.toString()
  ).toString();
  return encrypted;
};

export const decryptObj = (obj) => {
  const bytes = CryptoJS.AES.decrypt(obj, encryptKey.toString());
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedObject = JSON.parse(decryptedString);
  return decryptedObject;
};
