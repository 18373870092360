import * as React from "react";
import BarChart from "../../components/BarChart";
import {
  Box,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  CircularProgress,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import Header from "../../components/Header";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";
import Button from "@mui/material/Button";
import { Source, UploadOutlined } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import SportsGolfTwoToneIcon from "@mui/icons-material/SportsGolfTwoTone";
import SportsHockeyTwoToneIcon from "@mui/icons-material/SportsHockeyTwoTone";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Warning } from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import {
  SaveImportData,
  PublishImportData,
  GetImportData,
} from "../../services/Club.Service";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CheckCircle } from "@mui/icons-material"; // import { useTheme } from '@mui/material';
// import { tokens } from '../../theme';
import { ClubList } from "../../data/clublist";
import { ClubCvsList } from "../../data/clubcsvdata";
//import ClubModelsAccordion from "./club-models-accordion";
//import ShaftManagement from "./shafts-management";
import { lazy, Suspense } from "react";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

import NotificationsIcon from "@mui/icons-material/Notifications";
const ClubModelsAccordion = lazy(() => import("./club-models-accordion"));
const ShaftManagement = lazy(() => import("./shafts-management"));

const ClubManagementImport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state == null) {
    navigate("/club-management");
  }

  let { ReceivedData, SelectedBrandImport } = location.state;

  const confirm = useConfirm();

  const [APIData, setAPIData] = React.useState(ReceivedData);
  const [SourceData, setSourceData] = React.useState(null);

  //console.log(APIData == ReceivedData);

  const [Loading, setLoading] = React.useState(false);
  const [Saveloading, setSaveloading] = React.useState(false);
  const [Publishloading, setPublishloading] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [DialogOpen, setDialogOpen] = React.useState(false);

  const [ButtonStatus, setButtonStatus] = React.useState(false);

  const [DataCheck, setDataCheck] = React.useState(false);

  const [SourceDataChanged, setSourceDataChanged] = React.useState(false);

  const [DupError, setDupError] = React.useState([]);
  const [ErrorText, setErrorText] = React.useState("");
  const [DuplicateModel, setDuplicateModel] = React.useState([]);
  const [DuplicateShaft, setDuplicateShaft] = React.useState([]);

  //console.log(APIData);

  const [SelectedCategory, setSelectedCategory] = React.useState(
    ReceivedData.categoryId
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveData = async () => {
    setSaveloading(true);
    //console.log(APIData);
    try {
      var response = await SaveImportData(APIData);

      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaveloading(false);
      setSourceDataChanged(false);
    }
  };

  const publishData = async () => {
    setPublishloading(true);
    setDialogOpen(true);
    try {
      var response = await PublishImportData({
        logId: APIData.logId,
        brandId: APIData.brandId,
        categoryId: APIData.categoryId,
      });
      setDialogOpen(false);
      //  console.log(response);
      if (response.isSuccess == true) {
        toast.success(response?.result);
        navigate("/club-management");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPublishloading(false);
    }
  };

  const GetData = async () => {
    try {
      setAPIData(null);
      setSourceData(null);
      setLoading(true);
      setSourceDataChanged(false);
      var response = await GetImportData({
        logId: APIData.logId,
        brandId: APIData.brandId,
        categoryId: SelectedCategory,
      });
      if (response?.isSuccess == true && response.result != null) {
        setSourceData(JSON.parse(JSON.stringify(response.result)));
        setAPIData(response.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    GetData();
  }, [SelectedCategory]);

  const findShaftMasterDuplicates = async (arr) => {
    const seen = new Set();
    const duplicates = [];

    for (const item of APIData?.shaftMasterData?.configurationManagement[0]
      ?.club) {
      const compositeKey = `${item.configName.toLowerCase()}|${item.price}|${
        item.sku
      }|${item.status}`;

      if (seen.has(compositeKey)) {
        duplicates.push(item);
      } else {
        seen.add(compositeKey);
      }
    }

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Shaft Masters")));
    }
    return duplicates;
  };

  const findModelMasterDuplicates = async (arr) => {
    const seen = new Set();
    const duplicates = [];

    for (const item of APIData?.modelMasterDatas) {
      const compositeKey = `${item.modelName.toLowerCase()}|${item.status}`;

      if (seen.has(compositeKey)) {
        duplicates.push(item);
      } else {
        seen.add(compositeKey);
      }
    }

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Models Masters")));
    }

    return duplicates;
  };

  const findTableModelConfigDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData.modelConfigDatas.forEach((item) => {
      // Create a composite key using the specified parameters
      let key = `${item.modelId}-${item.configDetails.sex}-${item.configDetails.dexterity}-${item.configDetails.flex}-${item.configDetails.loft}-${item.status}-${item.configDetails?.bounce}-${item.configDetails?.grind}-${item.configDetails?.type}-${item.configDetails?.neck}`;

      if (lookup[key]) {
        duplicates.push(item);
      } else {
        lookup[key] = true;
      }
    });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Configuration")));
    }

    return duplicates;
  };

  const findFlexDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ShaftFlex" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateShaft(Array.from(DuplicateShaft.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });
    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Shaft Flex")));
    }

    return duplicates;
  };

  const findShaftDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ModelShaft" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateModel(Array.from(DuplicateModel.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Shaft")));
    }
    // console.log(duplicates);
    return duplicates;
  };

  const findGripDuplicates = async () => {
    let lookup = {};
    let duplicates = [];

    APIData?.mappingDatas
      ?.filter((item) => item.mapType == "ModelGrip" && item.status == true)
      .forEach((item) => {
        // Create a composite key using the specified parameters
        let key = `${item.brandId}-${item.categoryId}-${item.refId1}-${item.refId2}-${item.status}`;

        if (lookup[key]) {
          duplicates.push(item);
          setDuplicateModel(Array.from(DuplicateModel.push(item.refId1)));
        } else {
          lookup[key] = true;
        }
      });

    if (duplicates.length > 0) {
      setDupError(Array.from(DupError.push("Model Grip")));
    }
    // console.log(duplicates);
    return duplicates;
  };

  React.useEffect(() => {
    CheckButtonDisbaled();

    JSON.stringify(APIData) === JSON.stringify(SourceData)
      ? setSourceDataChanged(false)
      : setSourceDataChanged(true);
  }, [DataCheck]);

  const CheckButtonDisbaled = async () => {
    var shaft = await findShaftMasterDuplicates();
    var Model = await findModelMasterDuplicates();
    var TableData = await findTableModelConfigDuplicates();
    var FlexData = await findFlexDuplicates();
    var shaftData = await findShaftDuplicates();
    var gripData = await findGripDuplicates();

    console.log(shaft.length + " - " + Model.length + " - " + TableData.length);
    if (
      shaft.length > 0 ||
      Model.length > 0 ||
      TableData.length > 0 ||
      FlexData.length > 0 ||
      shaftData.length > 0 ||
      gripData.length > 0
    ) {
      var text = DupError.join(", ");

      setErrorText(`There was duplicate data in ${text}. Kindly check.`);

      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  return (
    <Box m="20px" height="75vh">
      {/* <Header title="Club Management" subtitle="Imported data" /> */}

      <Typography variant="h3" component="h3">
        Ensure the custom fit data imported from Excel is accurate
      </Typography>

      <Grid container spacing={3} columns={12} marginTop={1}>
        <Grid item xs={4} lg={3} xl={2}>
          <Paper
            elevation={0}
            sx={{ maxWidth: 256, backgroundColor: "#F2F0F0" }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    backgroundColor: "#F2F0F0",
                    color: "#B20021",
                    fontSize: 22,
                  }}
                >
                  Category
                </ListSubheader>
              }
            >
              {SelectedBrandImport &&
                SelectedBrandImport.categories
                  .sort((a, b) => a.categoryId - b.categoryId)
                  .map((brandItem) => (
                    <ListItemButton
                      key={brandItem?.categoryId}
                      selected={SelectedCategory === brandItem?.categoryId}
                      onClick={(event) => {
                        if (SourceDataChanged) {
                          confirm({
                            description:
                              "You have unsaved changes, are you sure you want to leave ?",
                          })
                            .then(() => {
                              setSelectedCategory(brandItem.categoryId);
                            })
                            .catch(() => {});
                        } else {
                          setSelectedCategory(brandItem.categoryId);
                        }
                      }}
                    >
                      <ListItemText primary={brandItem?.category} />
                    </ListItemButton>
                  ))}
            </List>
          </Paper>
        </Grid>
        {APIData != null ? (
          <Grid item xs={8} lg={9} xl={10}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    icon={<SportsGolfTwoToneIcon />}
                    label="Models"
                    value="1"
                  />
                  <Tab
                    icon={<SportsHockeyTwoToneIcon />}
                    label="Shafts"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Suspense fallback={<h1>Still Loading…</h1>}>
                  <ClubModelsAccordion
                    APIData={APIData}
                    DataCheck={DataCheck}
                    setDataCheck={setDataCheck}
                  />
                </Suspense>
              </TabPanel>
              <TabPanel value="2">
                <Suspense fallback={<h1>Still Loading…</h1>}>
                  <ShaftManagement
                    APIData={APIData}
                    DataCheck={DataCheck}
                    setDataCheck={setDataCheck}
                  />
                </Suspense>
              </TabPanel>
            </TabContext>

            {ButtonStatus ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Alert severity="error">{ErrorText}</Alert>
              </Box>
            ) : null}
            <Box
              sx={{
                margin: 3,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                sx={{ width: { sm: "45%" } }}
                disabled={ButtonStatus}
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={Saveloading ? null : <UploadOutlined />}
                onClick={(e) => saveData()}
              >
                {Saveloading ? (
                  <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                ) : (
                  "Save changes"
                )}
              </Button>
              <Button
                sx={{ width: { sm: "45%" } }}
                component="label"
                disabled={ButtonStatus}
                variant="contained"
                tabIndex={-1}
                startIcon={Publishloading ? null : <UploadOutlined />}
                onClick={(e) => publishData()}
              >
                {Publishloading ? (
                  <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                ) : (
                  "Publish Data"
                )}
              </Button>
            </Box>
          </Grid>
        ) : Loading ? (
          <Typography variant="h2" gutterBottom style={{ marginLeft: "20px" }}>
            Still Loading...
          </Typography>
        ) : (
          <Typography variant="h2" gutterBottom style={{ marginLeft: "20px" }}>
            No data found
          </Typography>
        )}
      </Grid>
      <Dialog open={DialogOpen} style={{ zIndex: 10001 }}>
        <LinearProgress color="primary" />
        <DialogTitle>
          <Typography
            color="primary"
            variant="h5"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <NotificationsIcon color="primary" sx={{ marginRight: 1 }} />
            Notification - Publish Data
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The publish of imported data has started. It will take few mins to
            complete
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ClubManagementImport;
