const apiEndpoints = {
  BASE_URL: process.env.REACT_APP_URL,
  BASE_PUBLIC_URL: process.env.REACT_APP_AUTH_URL,
  BASE_AUTH_URL: process.env.REACT_APP_AUTH_URL,
  USERS: {
    ADDUSERS: "User/AddUser",
    GETUSERS: "User/GetUsers",
    EDITUSERS: "User/GetUserProfile",
    UPDATEUSERS: "User/UpdateUser",
    UPDATEUSERSPASSWORD: "User/Password/updateUser",
    GETUSERIMAGEBYEMAIL: "User/Image/GetByEmail",
    UPDATEUSERIMAGE: "User/Image/Upload",
    GETUSERPROFILE: "User/GetUserProfile",
    GETUSERIMG: "/api/Images/Get",
    UPLOADUSERIMG: "/api/Images/Upload",
  },
  DECODEToken: "Account/DecodeToken",
  REFRESH_TOKEN: "Account/RefreshToken",
  BRANDS: {
    GET_BRAND: "ConfigurationManagement/ViewBrands",
  },

  LOGIN: {
    LOGIN_USER: "Account/Login",
    FORGET_PASSWORD: "Account/Password/Forget",
    RESET_PASSWORD: "Account/Password/Reset",
    CHANGE_PASSWORD: "Account/Password/Change",
  },
  CONFIG: {
    GET_CONFIG: "ConfigurationManagement/ViewConfigManagement",
    UPSERT_CONFIG: "ConfigurationManagement/UpsertConfigManagement",
  },
  CLUB: {
    UPSERTCLUB: "ClubManagement/UploadClubData",
    SAVEIMPORTDATA: "ClubManagement/SaveImportData",
    PUBLISHIMPORTDATA: "ClubManagement/PublishImportData",
    GETIMPORTDATA: "ClubManagement/GetImportData",
    GETMODELSFOREDIT: "ClubManagement/GetModelsForEdit",
    SAVECUSTOMFITDATAEDIT: "ClubManagement/SaveCustomFitDataEdit",
    GETSKU: "ClubManagement/GetSKU",
    SEARCHGRIP: "ClubManagement/GetAllGrips",
  },
};

export default apiEndpoints;
