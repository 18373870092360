import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  useTheme,
} from "@mui/material";

import { change_Password } from "../../services/users.services";
import { tokens } from "../../theme";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(12, "Password must be at most 12 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const ChangePassword = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { getUserDetails } = useAuth();
  const User = getUserDetails();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (
    { email, oldPassword, newPassword, confirmPassword, isCustomer },
    resetform
  ) => {
    console.log(oldPassword);
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await change_Password({
        email: User?.Email,
        oldPassword: oldPassword,
        newPassword: newPassword,
        isCustomer: false,
      });
      const { isSuccess, message, result } = response;
      if (isSuccess) {
        setSuccessMessage(result);
        setShowMessage(true);
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      console.log(error); // Log the error
    } finally {
      setLoading(false);
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2500);
      resetform();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          console.log("Closed");
        }
      }}
      sx={{
        "& .MuiDialog-paper": {
          padding: 2,
          borderRadius: 3,
          backgroundColor: "#f2f0f0",
          margin: "auto",
        },
      }}
    >
      <DialogTitle
        fontWeight="bold"
        variant="h4"
        textAlign="center"
        sx={{
          backgroundColor: "#f2f0f0",
          color: "#666666",
          padding: 2,
          borderRadius: 1,
          marginBottom: 3,
        }}
      >
        Change Password
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#B20021",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "400px" }}>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              email: "",
              isCustomer: true,
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ resetForm, errors, touched, handleBlur, isValid, dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      as={TextField}
                      sx={{ mt: 2 }}
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      label="Old Password"
                      fullWidth
                      disabled={showMessage}
                      onClick={() => setErrorMessage("")}
                      onBlur={handleBlur}
                      error={touched.oldPassword && !!errors.oldPassword}
                      helperText={touched.oldPassword && errors.oldPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowOldPassword(!showOldPassword)
                              }
                              edge="end"
                            >
                              {showOldPassword ? (
                                <VisibilityIcon sx={{ color: "#ad868d;" }} />
                              ) : (
                                <VisibilityOffIcon sx={{ color: "#ad868d;" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      label="New Password"
                      fullWidth
                      disabled={showMessage}
                      onClick={() => setErrorMessage("")}
                      onBlur={handleBlur}
                      error={touched.newPassword && !!errors.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityIcon sx={{ color: "#ad868d;" }} />
                              ) : (
                                <VisibilityOffIcon sx={{ color: "#ad868d;" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      label="Confirm Password"
                      fullWidth
                      disabled={showMessage}
                      onClick={() => setErrorMessage("")}
                      onBlur={handleBlur}
                      error={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon sx={{ color: "#ad868d;" }} />
                              ) : (
                                <VisibilityOffIcon sx={{ color: "#ad868d;" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: colors.agColors["red-darker"],
                        borderColor: colors.agColors["red-darker"],
                        color: colors.agColors["white"],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                      }}
                      disabled={loading || !dirty}
                    >
                      {loading ? <CircularProgress size={20} /> : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
        {successMessage.trim() !== "" && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" color="#B20021">
              {successMessage}
            </Typography>
          </Box>
        )}
        {errorMessage.trim() !== "" && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" color="#B20021">
              {errorMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
