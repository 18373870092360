import { useState, useEffect } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton, Avatar } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";

import { useAuth } from "../../../AuthContext";
import logo from "../../../assets/images/logo-new-bg.png";
import { authService } from "../../../services/auth.service";
import smlogo from "../../../assets/images/logo-white-sm.png";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography variant="subtitle2">{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  // user details
  const { getUserDetails, isLoggedIn } = useAuth();

  // const { Role, Name, Id } = getUserDetails();

  return (
    (isLoggedIn || authService.isAuthenticated()) && (
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 3,
          "& .sidebar": {
            border: "none",
          },
          "& .menu-icon": {
            backgroundColor: "transparent !important",
          },
          "& .menu-item": {
            // padding: "5px 35px 5px 20px !important",
            backgroundColor: "transparent !important",
          },
          "& .menu-anchor": {
            color: "inherit !important",
            backgroundColor: "transparent !important",
          },
          "& .menu-item:hover": {
            color: `${colors.agColors["red-darker"]} !important`,
            backgroundColor: "transparent !important",
          },
          "& .menu-item.active": {
            color: `${colors.agColors["red-darker"]} !important`,
            backgroundColor: "transparent !important",
          },
        }}
      >
        <Sidebar
          breakPoint="md"
          rtl={sidebarRTL}
          backgroundColor={colors.primary[400]}
          image={sidebarImage}
          width="290px"
        >
          <Menu iconshape="square">
            <MenuItem
              icon={
                collapsed ? (
                  <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                ) : sidebarRTL ? (
                  ""
                ) : (
                  ""
                )
              }
              style={{
                margin: "10px 0 20px 0",
                color: colors.agColors.grey,
              }}
            >
              {!collapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="5px"
                  pt="50px"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <img style={{ height: "30px" }} src={logo} />
                      <IconButton
                        onClick={
                          broken
                            ? () => toggleSidebar()
                            : () => collapseSidebar()
                        }
                      >
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box mt="20px" mb="20px">
                      <Typography
                        variant="h4"
                        color={colors.agColors.grey}
                        style={{ fontWeight: "bold" }}
                        // ml="3px"
                        // mr="5px"
                      >
                        CUSTOM FIT PORTAL
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </MenuItem>
            {!collapsed && (
              <Box mt="25px" mb="25px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    "& .avater-image": {
                      backgroundColor: colors.primary[500],
                    },
                  }}
                >
                  <img
                    className="avater-image"
                    alt="profile user"
                    width="100px"
                    height="100px"
                    src={`${process.env.REACT_APP_IMG_URL}get/0/${
                      getUserDetails()?.Id
                    }/null/false/null`}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h3"
                    color={colors.agColors.grey}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {getUserDetails()?.Name}
                  </Typography>
                  <Typography
                    variant="h5"
                    color={colors.agColors["red-darker"]}
                  >
                    {getUserDetails()?.Role}
                  </Typography>
                </Box>
              </Box>
            )}

            {collapsed && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <Avatar
                  src={smlogo}
                  alt="logo"
                  sx={{ width: 40, height: 40 }}
                />
              </Box>
            )}

            <Box paddingLeft={collapsed ? undefined : "10%"}>
              <Box
                style={{
                  backgroundColor:
                    selected === "Dashboard" ? "white" : "transparent",
                  borderRadius: selected === "Dashboard" ? "10px" : "0",
                  marginRight: "5px",
                }}
              >
                <Item
                  title="Dashboard"
                  to="/Dashboard"
                  icon={<HomeOutlinedIcon sx={{ color: "#ad868d" }} />}
                  selected={selected}
                  setSelected={setSelected}
                  style={{
                    backgroundColor:
                      selected === "Dashboard" ? "white" : "transparent",
                    borderRadius: selected === "Dashboard" ? "10px" : "0",
                    marginRight: "5px",
                  }}
                />
              </Box>

              <Box
                style={{
                  backgroundColor:
                    selected === "Club Management" ? "white" : "transparent",
                  borderRadius: selected === "Club Management" ? "10px" : "0",
                  marginRight: "5px",
                }}
              >
                <Item
                  title="Club Management"
                  to="/club-management"
                  icon={<PeopleOutlinedIcon sx={{ color: "#ad868d" }} />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>

              <Box
                style={{
                  backgroundColor:
                    selected === "Configuration Management"
                      ? "white"
                      : "transparent",
                  borderRadius:
                    selected === "Configuration Management" ? "10px" : "0",
                  marginRight: "5px",
                }}
              >
                <Item
                  title="Configuration Management"
                  to="/configuration-management"
                  icon={<ContactsOutlinedIcon sx={{ color: "#ad868d" }} />}
                  selected={selected}
                  setSelected={setSelected}
                  style={{
                    backgroundColor:
                      selected === "Configuration Management"
                        ? "Club Management"
                        : "transparent",
                    borderRadius: selected === "Club Management" ? "10px" : "0",
                    marginRight: "5px",
                  }}
                />
              </Box>
            </Box>
          </Menu>
        </Sidebar>
      </Box>
    )
  );
};

export default MyProSidebar;
