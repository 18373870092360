import axiosInstance, {
  axiosInstancePublic,
  axiosInstanceSecured,
} from "./axiosInstance";
import { AxiosResponse } from "axios";
import API_ENDPOINTS from "../config/apiEndpoints";
import apiEndpoints from "../config/apiEndpoints";

export const MyPreferenceImageUpload = async (payload) => {
  try {
    // const formData = new FormData();
    // formData.append('ImageFile', payload);
    const response = await axiosInstanceSecured.post(
      API_ENDPOINTS.IMAGE_UPLOAD.PREFERENCE_IMG_UPLOAD,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response:", response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const FetchUserImage = async (payload) => {
  const { Imageupload, MobileOrWeb, IsCustomer, UserId, BannerFileName } =
    payload;
  try {
    const response = await axiosInstanceSecured.get(
      `/get/${Imageupload}/${UserId}/${MobileOrWeb}/${IsCustomer}/${BannerFileName}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const GetUserProfile = async (id) => {
  try {
    const response = await axiosInstanceSecured.get(
      `${API_ENDPOINTS.USERS.GETUSERPROFILE}?userID=${id}`
    );
    console.log("res", response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
