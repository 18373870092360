import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, colors } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Button from "@mui/material/Button";
import { Stack, CircularProgress, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { GrindData } from "../../data/grinddata";
import { v4 as uuidv4 } from "uuid";
import { UpsertConfiguration } from "../../services/Configuration.Service";
import { GetConfigurationList } from "../../services/Configuration.Service";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

const GrindManagement = ({ BrandList, configList }) => {
  let intialBrandId = null;
  const [GrindList, setGrindList] = React.useState(configList);
  const [selectedIndex, setSelectedIndex] = React.useState(intialBrandId);
  const [selectedCategory, setCategoryValue] = React.useState("1");
  const [loading, setLoading] = React.useState(false);
  const [Reload, setReload] = useState(false);
  const [ValidationError, setValidationError] = useState([]);
  const [GetDataLoad, setDataLoad] = useState(false);
  const [InitialGrindData, setInitialGrindData] = useState([]);
  const confirm = useConfirm();
  const getConfigData = async () => {
    try {
      setDataLoad(true);
      var ClubType = BrandList.find(
        (item) => item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
      )?.categories.find((item) => item.categoryId == selectedCategory);

      var response = await GetConfigurationList(
        "Grind",
        selectedIndex,
        ClubType.category
      );
      if (response?.isSuccess == true) {
        response?.result.forEach((element) => {
          element.configJson = JSON.parse(element.configJson);
        });

        let PreparedData = [];

        response?.result.forEach((element) => {
          element.configJson?.ConfigurationManagement[0]?.club?.forEach(
            (element1) => {
              // console.log(element1);
              PreparedData.push({
                primaryId: element.Id,
                clubType: element1.clubType,
                configId: element1.configId,
                configName: element1.configName,
                friendlyName: element1.friendlyName,
                isDeleted: element1.isDeleted,
                manufacturer: element1.manufacturer,
                price: element1.price,
                sku: element1.sku,
                status: element1.status,
                Brand: element.Brand,
                configurationType: element.configurationType,
              });
            }
          );
        });
        setInitialGrindData(PreparedData);
        setGrindList(PreparedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoad(false);
    }
  };

  React.useEffect(() => {
    if (selectedIndex != null && selectedCategory != null) {
      getConfigData();
    }
  }, [Reload, selectedIndex, selectedCategory]);

  React.useEffect(() => {
    setSelectedIndex(
      BrandList && BrandList.length > 0 ? BrandList[0].Brand : null
    );
  }, []);

  const findMaxValue = async () => {
    let maxId = 0;
    maxId = GrindList?.filter(
      (item) =>
        item.Brand.toLowerCase() == selectedIndex?.toLowerCase() &&
        item.configurationType == "Grind"
    )?.reduce((max, obj) => (obj.configId > max ? obj.configId : max), 0);

    maxId = maxId + 1;

    console.log("The maximum ID is:", maxId);

    return maxId;
  };

  const addNewItem = async () => {
    var clubType = BrandList.find(
      (item) => item.Brand == selectedIndex
    )?.categories.find((x) => x.categoryId == selectedCategory);

    var data = GrindList.find(
      (item) =>
        item.Brand == selectedIndex &&
        item.isDeleted == false &&
        item.configurationType == "Grind"
    );
    console.log(data);

    const newitem = {
      primaryId: data?.primaryId ? data.primaryId : 0,
      configId: await findMaxValue(),
      configName: "",
      friendlyName: "",
      manufacturer: "",
      Brand: selectedIndex,
      clubType: clubType?.category,
      configurationType: "Grind",
      price: 0,
      sku: "",
      status: true,
      isDeleted: false,
    };

    const validationKey = `${newitem.Brand}_${newitem.configId}_${newitem.primaryId}_${newitem.configurationType}`;
    setValidationError((prevErrors) => [...prevErrors, validationKey]);

    GrindList.push(newitem);

    setGrindList(Array.from(GrindList));
  };

  const handleListItemClick = async (event, index) => {
    var ComparedData = await compareData();
    if (ComparedData.length > 0) {
      confirm({
        description:
          "You have unsaved changes, are you sure you want to leave ?",
      })
        .then(() => {
          setSelectedIndex(index);
        })
        .catch(() => {});
    } else {
      setSelectedIndex(index);
    }
  };

  const compareData = () => {
    let changes = [];

    InitialGrindData.forEach((oldItem) => {
      const newItem = GrindList.find(
        (item) => item.configId === oldItem.configId
      );
      if (newItem) {
        if (
          oldItem.configName !== newItem.configName ||
          oldItem.clubType !== newItem.clubType ||
          oldItem.price !== newItem.price ||
          oldItem.isDeleted !== newItem.isDeleted ||
          oldItem.sku !== newItem.sku ||
          oldItem.status !== newItem.status
        ) {
          changes.push(oldItem.configId);
        }
      }
    });

    return changes;
  };

  const handleCategoryChange = async (event, newValue) => {
    var ComparedData = await compareData();
    if (ComparedData.length > 0) {
      confirm({
        description:
          "You have unsaved changes, are you sure you want to leave ?",
      })
        .then(() => {
          setCategoryValue(newValue);
        })
        .catch(() => {});
    } else {
      setCategoryValue(newValue);
    }
  };

  const GetItemList = (CatId) => {
    var data = GrindList.filter(
      (item) =>
        item.clubType == CatId &&
        item.Brand.toLowerCase() == selectedIndex.toLowerCase() &&
        item.isDeleted == false &&
        item.configurationType == "Grind"
    );
    return data;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      var ClubType = BrandList.find(
        (item) => item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
      )?.categories.find((item) => item.categoryId == selectedCategory);

      let primaryId = 0;

      var consolidatedData = [];
      GrindList.forEach((element) => {
        if (element.configName != "" && element.configName.trim().length >= 1) {
          primaryId = element.primaryId;
          consolidatedData.push({
            configId: element.configId,
            clubType: element.clubType,
            configName: element.configName,
            friendlyName: element.friendlyName,
            manufacturer: element.manufacturer,
            price: element.price,
            sku: element.sku,
            status: element.status,
            isDeleted: element.isDeleted,
            configurationType: element.configurationType,
          });
        }
      });

      var finalData = {
        ConfigurationManagement: [
          {
            id: primaryId,
            brand: selectedIndex,
            ConfigurationType: "Grind",
            club: consolidatedData,
          },
        ],
      };
      var RequestData = {
        Id: primaryId,
        Brand: selectedIndex,
        clubType: ClubType.category,
        ConfigurationType: "Grind",
        configMasters: JSON.stringify(finalData),
      };

      var response = await UpsertConfiguration(RequestData);
      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setReload(!Reload);
    }
  };

  return (
    <Grid container spacing={2} marginTop={6}>
      <Grid item xs={4} lg={3} xl={2}>
        <Paper elevation={0} sx={{ maxWidth: 256, backgroundColor: "#F2F0F0" }}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  backgroundColor: "#F2F0F0",
                  color: "#B20021",
                  fontSize: 22,
                }}
              >
                Brands
              </ListSubheader>
            }
          >
            {BrandList &&
              BrandList.length > 0 &&
              BrandList.map((brandItem) => (
                <ListItemButton
                  key={brandItem?.Brand}
                  selected={selectedIndex === brandItem?.Brand}
                  onClick={(event) =>
                    handleListItemClick(event, brandItem?.Brand)
                  }
                >
                  <ListItemText primary={brandItem?.Brand} />
                </ListItemButton>
              ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={8} lg={9} xl={10}>
        <TabContext value={selectedCategory}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleCategoryChange}>
              {BrandList.find(
                (item) => item.Brand == selectedIndex
              )?.categories?.map((CatItem) => (
                <Tab
                  label={CatItem.category}
                  value={CatItem.categoryId.toString()}
                  key={CatItem.categoryId}
                />
              ))}
            </TabList>
          </Box>
          {BrandList.find((item) => item.Brand == selectedIndex)
            ?.categories?.sort((a, b) => a.categoryId - b.categoryId)
            .map((CatItem) => (
              <TabPanel
                value={CatItem.categoryId.toString()}
                key={CatItem.categoryId}
              >
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  {GetItemList(CatItem.category).map((GrindItem, index) => (
                    <Box
                      sx={{
                        marginRight: 4,
                        marginTop: 3,
                        height: 40,
                        backgroundColor: "#F2F0F0",

                        borderRadius: 16,
                        border: ValidationError.includes(
                          `${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}`
                        )
                          ? "1px solid red"
                          : "1px solid #bdbdbd",
                      }}
                      direction="row"
                      useFlexGap
                      flexWrap="wrap"
                      key={`${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}_${index}`}
                    >
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={GrindItem.status}
                        onChange={(event) => {
                          setGrindList((prevItems) =>
                            prevItems.map((item) =>
                              item.configId === GrindItem.configId &&
                              item.Brand.toLowerCase() ==
                                selectedIndex?.toLowerCase()
                                ? { ...item, status: event.target.checked }
                                : item
                            )
                          );
                        }}
                      />
                      <TextField
                        id="standard-textname"
                        defaultValue={GrindItem.configName}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        placeholder="Enter Value"
                        sx={{
                          marginTop: 0.6,
                          marginLeft: 0.5,
                          width: `${
                            GrindItem.configName.length > 0
                              ? GrindItem.configName.length * 9
                              : 100
                          }px`,
                        }}
                        onChange={(event) => {
                          const validationKey = `${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}`;

                          setGrindList((prevItems) =>
                            prevItems.map((item) =>
                              item.configId === GrindItem.configId &&
                              item.Brand.toLowerCase() ==
                                selectedIndex?.toLowerCase()
                                ? { ...item, configName: event.target.value }
                                : item
                            )
                          );

                          if (event.target.value.trim().length < 2) {
                            setValidationError((prevErrors) =>
                              prevErrors.filter(
                                (error) => error !== validationKey
                              )
                            );
                          }
                        }}
                        onBlur={(e) => {
                          const validationKey = `${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}`;
                          if (
                            e.target.value == null ||
                            e.target.value.trim() == ""
                          ) {
                            setValidationError((prevErrors) => [
                              ...prevErrors,
                              validationKey,
                            ]);
                          } else if (
                            GrindList.filter(
                              (obj) =>
                                obj.Brand === GrindItem.Brand &&
                                obj.configurationType ==
                                  GrindItem.configurationType &&
                                obj.configName.trim().toLowerCase() ==
                                  e.target.value.trim().toLowerCase()
                            ).length > 1
                          ) {
                            setValidationError((prevErrors) => [
                              ...prevErrors,
                              validationKey,
                            ]);
                          } else {
                            setValidationError((prevErrors) =>
                              prevErrors.filter(
                                (error) => error !== validationKey
                              )
                            );
                          }
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          confirm({
                            description: "Do you want to delete this record ?",
                          })
                            .then(() => {
                              setGrindList((prevItems) =>
                                prevItems.map((item) =>
                                  item.configId === GrindItem.configId &&
                                  item.Brand.toLowerCase() ==
                                    selectedIndex?.toLowerCase()
                                    ? { ...item, isDeleted: true }
                                    : item
                                )
                              );
                              const validationKey = `${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}`;

                              setValidationError((prevErrors) =>
                                prevErrors.filter(
                                  (error) => error !== validationKey
                                )
                              );
                            })
                            .catch(() => {});
                        }}
                      >
                        <CancelIcon sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                      </IconButton>
                      {ValidationError.includes(
                        `${GrindItem.Brand}_${GrindItem.configId}_${GrindItem.primaryId}_${GrindItem.configurationType}`
                      ) ? (
                        GrindItem.configName == null ||
                        GrindItem.configName.trim() == "" ? (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            color="primary"
                          >
                            Grind name is mandatroy
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            color="primary"
                          >
                            Grind name already exists
                          </Typography>
                        )
                      ) : null}
                    </Box>
                  ))}

                  {GetDataLoad && (
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ textAlign: "center" }}
                    >
                      Loading...
                    </Typography>
                  )}

                  {!GetDataLoad &&
                  selectedIndex != null &&
                  GetItemList(CatItem.category).length <= 0 ? (
                    <h3 style={{ marginTop: 30, marginRight: 5 }}>
                      No records found. Click 'Add New' to create a new one.
                    </h3>
                  ) : null}
                  {!GetDataLoad && selectedIndex != null ? (
                    <Button
                      variant="contained"
                      tabIndex={-1}
                      sx={{ marginRight: 4, marginTop: 3, height: 40 }}
                      startIcon={<AddCircleTwoToneIcon />}
                      onClick={addNewItem}
                    >
                      Add New
                    </Button>
                  ) : null}
                </Stack>
                {!GetDataLoad && selectedIndex != null ? (
                  <Box
                    sx={{
                      marginTop: 10,
                      marginLeft: 20,
                      marginRight: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <Button
                      sx={{ width: { sm: "100%" } }}
                      component="label"
                      size="large"
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<SaveTwoToneIcon />}
                      onClick={handleSubmit}
                      disabled={ValidationError.length > 0 ? true : false}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Commit Changes"
                      )}
                    </Button>
                  </Box>
                ) : null}
              </TabPanel>
            ))}
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default GrindManagement;
