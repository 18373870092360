import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LoftData } from "../../data/loftdata";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Button from "@mui/material/Button";
import { Box, Stack, CircularProgress, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import { v4 as uuidv4 } from "uuid";
import { UpsertConfiguration } from "../../services/Configuration.Service";
import { GetConfigurationList } from "../../services/Configuration.Service";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

const LoftManagement = ({ BrandList, configList }) => {
  let intialBrandId = null;

  const [LoftList, setLoftList] = React.useState(configList);
  const [selectedIndex, setSelectedIndex] = React.useState(intialBrandId);
  const [selectedCategory, setCategoryValue] = React.useState("1");
  const [loading, setLoading] = React.useState(false);
  const [Reload, setReload] = useState(false);
  const [ValidationError, setValidationError] = useState([]);
  const [GetDataLoad, setDataLoad] = useState(false);
  const [InitialLoftData, setInitialLoftData] = useState([]);
  const confirm = useConfirm();

  const getConfigData = async () => {
    try {
      setDataLoad(true);
      var ClubType = BrandList.find(
        (item) => item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
      )?.categories.find((item) => item.categoryId == selectedCategory);

      var response = await GetConfigurationList(
        "Loft",
        selectedIndex,
        ClubType.category
      );
      if (response?.isSuccess == true) {
        response?.result.forEach((element) => {
          element.configJson = JSON.parse(element.configJson);
        });

        let PreparedData = [];

        response?.result.forEach((element) => {
          element.configJson?.ConfigurationManagement[0]?.club?.forEach(
            (element1) => {
              // console.log(element1);
              PreparedData.push({
                primaryId: element.Id,
                clubType: element1.clubType,
                configId: element1.configId,
                configName: element1.configName,
                friendlyName: element1.friendlyName,
                isDeleted: element1.isDeleted,
                manufacturer: element1.manufacturer,
                price: element1.price,
                sku: element1.sku,
                status: element1.status,
                Brand: element.Brand,
                configurationType: element.configurationType,
              });
            }
          );
        });

        console.log(PreparedData);

        setInitialLoftData(PreparedData);
        setLoftList(PreparedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoad(false);
    }
  };

  React.useEffect(() => {
    if (selectedIndex != null && selectedCategory != null) {
      getConfigData();
    }
  }, [Reload, selectedIndex, selectedCategory]);

  React.useEffect(() => {
    setSelectedIndex(
      BrandList && BrandList.length > 0 ? BrandList[0].Brand : null
    );
  }, []);

  const findMaxValue = async () => {
    let maxId = 0;
    maxId = LoftList?.filter(
      (item) =>
        item.Brand.toLowerCase() == selectedIndex?.toLowerCase() &&
        item.configurationType == "Loft"
    )?.reduce((max, obj) => (obj.configId > max ? obj.configId : max), 0);

    maxId = maxId + 1;

    return maxId;
  };

  const addNewItem = async () => {
    var clubType = BrandList.find(
      (item) => item.Brand == selectedIndex
    )?.categories.find((x) => x.categoryId == selectedCategory);

    var data = LoftList.find(
      (item) =>
        item.Brand == selectedIndex &&
        item.isDeleted == false &&
        item.configurationType == "Loft"
    );

    const newitem = {
      primaryId: data?.primaryId ? data.primaryId : 0,
      configId: await findMaxValue(),
      configName: "",
      friendlyName: "",
      manufacturer: "",
      Brand: selectedIndex,
      clubType: clubType?.category,
      configurationType: "Loft",
      price: 0,
      sku: "",
      status: true,
      isDeleted: false,
    };

    const validationKey = `${newitem.Brand}_${newitem.configId}_${newitem.primaryId}_${newitem.configurationType}`;
    setValidationError((prevErrors) => [...prevErrors, validationKey]);

    LoftList.push(newitem);
    setLoftList(Array.from(LoftList));
  };
  const handleListItemClick = async (event, index) => {
    var ComparedData = await compareData();
    if (ComparedData.length > 0) {
      confirm({
        description:
          "You have unsaved changes, are you sure you want to leave ?",
      })
        .then(() => {
          setSelectedIndex(index);
        })
        .catch(() => {});
    } else {
      setSelectedIndex(index);
    }
  };

  const compareData = () => {
    let changes = [];

    InitialLoftData.forEach((oldItem) => {
      const newItem = LoftList.find(
        (item) => item.configId === oldItem.configId
      );
      if (newItem) {
        if (
          oldItem.configName !== newItem.configName ||
          oldItem.clubType !== newItem.clubType ||
          oldItem.price !== newItem.price ||
          oldItem.isDeleted !== newItem.isDeleted ||
          oldItem.sku !== newItem.sku ||
          oldItem.status !== newItem.status
        ) {
          changes.push(oldItem.configId);
        }
      }
    });

    return changes;
  };

  const handleCategoryChange = async (event, newValue) => {
    var ComparedData = await compareData();
    if (ComparedData.length > 0) {
      confirm({
        description:
          "You have unsaved changes, are you sure you want to leave ?",
      })
        .then(() => {
          setCategoryValue(newValue);
        })
        .catch(() => {});
    } else {
      setCategoryValue(newValue);
    }
  };

  const GetItemList = (CatId) => {
    var data = LoftList.filter(
      (item) =>
        item.clubType == CatId &&
        item.Brand.toLowerCase() == selectedIndex.toLowerCase() &&
        item.isDeleted == false &&
        item.configurationType == "Loft"
    );

    return data;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let primaryId = 0;
      var ClubType = BrandList.find(
        (item) => item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
      )?.categories.find((item) => item.categoryId == selectedCategory);

      var consolidatedData = [];
      LoftList.forEach((element) => {
        primaryId = element.primaryId;

        if (element.configName != "" && element.configName.trim().length >= 1) {
          consolidatedData.push({
            configId: element.configId,
            clubType: element.clubType,
            configName: element.configName,
            friendlyName: element.friendlyName,
            manufacturer: element.manufacturer,
            price: element.price,
            sku: element.sku,
            status: element.status,
            isDeleted: element.isDeleted,
            configurationType: element.configurationType,
          });
        }
      });

      var finalData = {
        ConfigurationManagement: [
          {
            id: primaryId,
            brand: selectedIndex,
            configurationType: "Loft",
            club: consolidatedData,
          },
        ],
      };

      var RequestData = {
        Id: primaryId,
        Brand: selectedIndex,
        clubType: ClubType.category,
        configurationType: "Loft",
        configMasters: JSON.stringify(finalData),
      };

      var response = await UpsertConfiguration(RequestData);
      if (response.isSuccess == true) {
        toast.success(response?.result);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setReload(!Reload);
    }
  };

  return (
    <Grid container spacing={2} marginTop={6}>
      <Grid item xs={4} lg={3} xl={2}>
        <Paper elevation={0} sx={{ maxWidth: 256, backgroundColor: "#F2F0F0" }}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{
                  backgroundColor: "#F2F0F0",
                  color: "#B20021",
                  fontSize: 22,
                }}
              >
                Brands
              </ListSubheader>
            }
          >
            {BrandList &&
              BrandList.length > 0 &&
              BrandList.map((brandItem) => (
                <ListItemButton
                  key={brandItem?.Brand}
                  selected={selectedIndex === brandItem?.Brand}
                  onClick={(event) =>
                    handleListItemClick(event, brandItem?.Brand)
                  }
                >
                  <ListItemText primary={brandItem?.Brand} />
                </ListItemButton>
              ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={8} lg={9} xl={10}>
        <TabContext value={selectedCategory}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleCategoryChange}>
              {BrandList.find(
                (item) =>
                  item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
              )
                ?.categories?.sort((a, b) => a.categoryId - b.categoryId)
                .map((CatItem) => (
                  <Tab
                    label={CatItem.category}
                    value={CatItem.categoryId.toString()}
                    key={CatItem.categoryId}
                  />
                ))}
            </TabList>
          </Box>
          {BrandList.find(
            (item) => item.Brand.toLowerCase() == selectedIndex?.toLowerCase()
          )?.categories?.map((CatItem) => (
            <TabPanel
              value={CatItem.categoryId.toString()}
              key={CatItem.categoryId}
            >
              <Stack direction="row" useFlexGap flexWrap="wrap">
                {GetItemList(CatItem.category).map((loftItem, index) => (
                  <Box
                    sx={{
                      marginRight: 4,
                      marginTop: 3,
                      height: 40,
                      backgroundColor: "#F2F0F0",

                      width: "auto",
                      borderRadius: 16,
                      border: ValidationError.includes(
                        `${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}`
                      )
                        ? "1px solid red"
                        : "1px solid #bdbdbd",
                    }}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                    key={`${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}_${index}`}
                  >
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={loftItem.status}
                      onChange={(event) => {
                        setLoftList((prevItems) =>
                          prevItems.map((item) =>
                            item.configId === loftItem.configId &&
                            item.Brand.toLowerCase() ==
                              selectedIndex?.toLowerCase()
                              ? { ...item, status: event.target.checked }
                              : item
                          )
                        );
                      }}
                    />
                    <TextField
                      id="standard-textname"
                      defaultValue={loftItem.configName}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder="Enter Value"
                      sx={{
                        marginTop: 0.6,
                        marginLeft: 0.5,
                        width: `${
                          loftItem.configName.length > 0
                            ? loftItem.configName.length * 9
                            : 100
                        }px`,
                      }}
                      onChange={(event) => {
                        const validationKey = `${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}`;

                        setLoftList((prevItems) =>
                          prevItems.map((item) =>
                            item.configId === loftItem.configId &&
                            item.Brand.toLowerCase() ==
                              selectedIndex?.toLowerCase()
                              ? { ...item, configName: event.target.value }
                              : item
                          )
                        );
                        if (event.target.value.trim().length < 2) {
                          setValidationError((prevErrors) =>
                            prevErrors.filter(
                              (error) => error !== validationKey
                            )
                          );
                        }
                      }}
                      onBlur={(e) => {
                        console.log(e.target.value);
                        console.log(
                          LoftList.filter(
                            (obj) =>
                              obj.Brand === loftItem.Brand &&
                              obj.configurationType ==
                                loftItem.configurationType &&
                              obj.configName.trim().toLowerCase() ===
                                e.target.value.trim().toLowerCase()
                          )
                        );
                        const validationKey = `${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}`;
                        if (
                          e.target.value == null ||
                          e.target.value.trim() == ""
                        ) {
                          setValidationError((prevErrors) => [
                            ...prevErrors,
                            validationKey,
                          ]);
                        } else if (
                          LoftList.filter(
                            (obj) =>
                              obj.Brand === loftItem.Brand &&
                              obj.configurationType ==
                                loftItem.configurationType &&
                              obj.configName.trim().toLowerCase() ==
                                e.target.value.trim().toLowerCase()
                          ).length > 1
                        ) {
                          setValidationError((prevErrors) => [
                            ...prevErrors,
                            validationKey,
                          ]);
                        } else {
                          setValidationError((prevErrors) =>
                            prevErrors.filter(
                              (error) => error !== validationKey
                            )
                          );
                        }
                      }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        confirm({
                          description: "Do you want to delete this record ?",
                        })
                          .then(() => {
                            setLoftList((prevItems) =>
                              prevItems.map((item) =>
                                item.configId === loftItem.configId &&
                                item.Brand.toLowerCase() ==
                                  selectedIndex?.toLowerCase()
                                  ? { ...item, isDeleted: true }
                                  : item
                              )
                            );
                            const validationKey = `${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}`;

                            setValidationError((prevErrors) =>
                              prevErrors.filter(
                                (error) => error !== validationKey
                              )
                            );
                          })
                          .catch(() => {});
                      }}
                    >
                      <CancelIcon sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                    </IconButton>

                    {ValidationError.includes(
                      `${loftItem.Brand}_${loftItem.configId}_${loftItem.primaryId}_${loftItem.configurationType}`
                    ) ? (
                      loftItem.configName == null ||
                      loftItem.configName.trim() == "" ? (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="primary"
                        >
                          Loft name is mandatroy
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="primary"
                        >
                          Loft name already exists
                        </Typography>
                      )
                    ) : null}
                  </Box>
                ))}

                {GetDataLoad && (
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{ textAlign: "center" }}
                  >
                    Loading...
                  </Typography>
                )}

                {!GetDataLoad &&
                  selectedIndex != null &&
                  GetItemList(CatItem.category).length <= 0 && (
                    <h3 style={{ marginTop: 30, marginRight: 5 }}>
                      No records found. Click 'Add New' to create a new one.
                    </h3>
                  )}
                {!GetDataLoad && selectedIndex != null ? (
                  <Button
                    variant="contained"
                    tabIndex={-1}
                    sx={{ marginRight: 4, marginTop: 3, height: 40 }}
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={addNewItem}
                  >
                    Add New
                  </Button>
                ) : null}
              </Stack>
              {!GetDataLoad && selectedIndex != null ? (
                <Box
                  sx={{
                    marginTop: 3,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 10,
                  }}
                >
                  <Button
                    sx={{ width: { sm: "100%" } }}
                    component="label"
                    size="large"
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<SaveTwoToneIcon />}
                    onClick={handleSubmit}
                    disabled={ValidationError.length > 0 ? true : false}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Commit Changes"
                    )}
                  </Button>
                </Box>
              ) : null}
            </TabPanel>
          ))}
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default LoftManagement;
