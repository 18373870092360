import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography
        variant="h3"
        // color={colors.agColors["grey-dark"]}
        color={colors.agColors.grey}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.agColors["red-darker"]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
