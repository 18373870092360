import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import API_ENDPOINTS from "../../config/apiEndpoints";
import { Email as EmailIcon, Lock } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import "./Login.css";
import { useAuth } from "../../AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Paper, useTheme } from "@mui/material";
import { AxiosResponse } from "axios";
import { axiosInstancePublic } from "../../services/axiosInstance";
import { tokens } from "../../theme";
import logoWhite from "../../assets/images/logo-white-sm.png";

export default function Login() {
  const { resetToken } = useParams();
  const token = localStorage.getItem("token");
  const [redirecting, setRedirecting] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { login, loading, loginError, setLoginErr, setLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const isResetPasswordWithToken =
      location.pathname.includes("/reset-password/");
    if (isResetPasswordWithToken) setShowChangePassword(!showChangePassword);
  }, [location.pathname]);
  useEffect(() => {
    if (token) {
      navigate("/Dashboard");
    }
  }, [token, navigate]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    changePassword: "",
    rememberMe: false,
    isCustomer: false,
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    changePassword: "",
  });
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const handleTogglenewPasswordVisibility = () => {
    setNewShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const isValidEmail = (email) => {
    const emailPattern =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return email.search(emailPattern) == -1 ? false : true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setLoginErr("");
  };
  const resetErrors = (e) => {
    const { name } = e.target;
    setFormErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };
  const formValidation = () => {
    const errors = {};
    const isResetPasswordWithToken =
      location.pathname.includes("/reset-password/");
    if (!isResetPasswordWithToken) {
      if (!formData.email) {
        errors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        errors.email = "Invalid Email";
      }
    }
    if (!showForgetPassword) {
      if (!formData.password) {
        errors.password = "Password is required";
      }
    }
    // } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/.test(formData.password)) {
    //   errors.password = "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and be between 6 and 12 characters long";
    // }
    if (showChangePassword) {
      if (!formData.changePassword && location.pathname == "/changePassword") {
        errors.changePassword = `Change Password is required`;
      } else if (!formData.changePassword) {
        errors.changePassword = `Confirm Password is required`;
      }
      // } else if (
      //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/.test(formData.changePassword)
      // ) {
      //   errors.changePassword =
      //     "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and be between 6 and 12 characters long";
      // }
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0 ? true : false;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const label = name.charAt(0).toUpperCase() + name.slice(1);
    if (value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: `${label} is required` });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };
  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      rememberMe: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("formvalidation:", formValidation());
    if (formValidation()) {
      !showChangePassword && delete formData["changePassword"];
      showChangePassword
        ? handleResetPassword(formData)
        : showForgetPassword
        ? await handleForgetPassword(formData.email)
        : await login(formData).then((res) => {
            if (res?.isSuccess) {
              navigate("/Dashboard");
            }
          });
    }
  };
  const handleForgetPassword = async (email) => {
    setLoading(true);
    setLoginErr("");
    setSuccessMessage("");
    try {
      const response = await axiosInstancePublic.put(
        `${API_ENDPOINTS.LOGIN.FORGET_PASSWORD}?Email=${email}`
      );
      if (response.data.isSuccess && response.status === 200) {
        setLoading(false);
        // const responseData: string = response.data.result;
        // const startIndex: number = responseData.indexOf("Email.") + 6;
        // const token: string = responseData.substring(startIndex);
        // localStorage.setItem("resetToken", token.trim());
        setSuccessMessage(
          "Password reset Link sent successfully to your email !"
        );
        return response.data;
      } else {
        setLoading(false);
        setLoginErr(response.data.message);
        if (!response.data.isSuccess) throw new Error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const handleResetPassword = async (formData) => {
    setLoading(true);
    // const resettoken = localStorage.getItem("resetToken");
    if (formData.password.trim() !== formData.changePassword.trim()) {
      setLoginErr("Passwords do not match");
      setLoading(false);
      return;
    }
    const data = {
      token: resetToken,
      password: formData.password,
    };
    try {
      const response = await axiosInstancePublic.put(
        API_ENDPOINTS.LOGIN.RESET_PASSWORD,
        data
      );
      if (!response?.data?.isSucess) {
        setLoading(false);
        setLoginErr(response.data.message);
      }
      //  localStorage.removeItem("resetToken");
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  return (
    <Card>
      <Container style={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <img style={{ width: "60px" }} src={logoWhite}></img>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              color: "#B20021",
              fontWeight: "900",
              mb: 2,
            }}
            variant="h3"
          >
            AG CUSTOM FIT PORTAL
          </Typography>
          {!redirecting && (
            <Paper
              elevation={2}
              sx={{
                padding: "40px",
                width: "450px",
                "@media (max-width: 600px)": {
                  width: "90%",
                  padding: "20px",
                  m: 2,
                },
              }}
            >
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "1.2rem",
                    color: "#B20021",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                >
                  {showForgetPassword
                    ? "FORGOT PASSWORD"
                    : showChangePassword
                    ? "SET PASSWORD"
                    : "LOGIN"}
                </Typography>
              </div>
              <Box component="form" noValidate sx={{ mt: 5 }}>
                {!showChangePassword && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Username <span style={{ color: "#B20021" }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      autoComplete="off"
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      onClick={(event) => resetErrors(event)}
                      onBlur={handleBlur}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      placeholder="Email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
                {!showForgetPassword && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2">
                      {showChangePassword ? "New Password" : "Password"}{" "}
                      <span style={{ color: "#B20021" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      autoComplete="new-password"
                      id="password"
                      name="password"
                      type={newShowPassword ? "text" : "password"}
                      value={formData.password}
                      onChange={handleInputChange}
                      onClick={(event) => resetErrors(event)}
                      onBlur={handleBlur}
                      error={!!formErrors.password}
                      helperText={formErrors.password}
                      placeholder="******"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglenewPasswordVisibility}
                              edge="end"
                            >
                              {!newShowPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Box>
                )}
                {showChangePassword && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2">
                      Confirm Password{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      autoComplete="new-password"
                      id="changePassword"
                      name="changePassword"
                      type={confirmShowPassword ? "text" : "password"}
                      value={formData.changePassword}
                      onChange={handleInputChange}
                      onClick={(event) => resetErrors(event)}
                      onBlur={handleBlur}
                      error={!!formErrors.changePassword}
                      helperText={formErrors.changePassword}
                      placeholder="******"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleToggleConfirmPasswordVisibility}
                              edge="end"
                            >
                              {confirmShowPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Box>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: colors.agColors["red-darker"],
                    borderColor: colors.agColors["red-darker"],
                    color: colors.agColors["white"],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    mt: 3,
                    mb: 2,
                    textTransform: "none",
                  }}
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : showForgetPassword || showChangePassword ? (
                    "SUBMIT"
                  ) : (
                    "LOGIN"
                  )}
                </Button>
                {/* {showForgetPassword && <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  disabled={loading}
                  sx={{  mb: 2, textTransform: "none" }}
                  onClick={() =>{
                     setFormData({...formData,email:''})
                     setShowForgetPassword(!showForgetPassword)
                     setLoginErr("")
                    }}        
                >
                Cancel
                </Button>} */}

                {showForgetPassword && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Link
                      variant="subtitle2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFormData({ ...formData, email: "" });
                        setShowForgetPassword(!showForgetPassword);
                        setLoginErr("");
                        setSuccessMessage("");
                      }}
                    >
                      Back to login
                    </Link>
                  </Box>
                )}
              </Box>
              {loginError.trim() !== "" && (
                <p
                  style={{
                    color: "#B20021",
                    textAlign: "center",
                    fontSize: "0.7rem",
                  }}
                >
                  {loginError} !
                </p>
              )}
              {successMessage.trim() !== "" && (
                <p
                  style={{
                    color: "#B20021",
                    textAlign: "center",
                    fontSize: "0.7rem",
                  }}
                >
                  {successMessage}
                </p>
              )}
              {!showChangePassword && (
                <Grid
                  container
                  alignItems="center"
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {!showForgetPassword && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={formData.rememberMe}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="subtitle2">Remember me</Typography>
                      }
                    />
                  )}
                  <Box>
                    <Link
                      variant="subtitle2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowForgetPassword(true)}
                    >
                      {!showForgetPassword && "Forgot password?"}
                    </Link>
                  </Box>
                </Grid>
              )}
            </Paper>
          )}
        </Box>
      </Container>
    </Card>
  );
}
