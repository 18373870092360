import axiosInstance from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

var data = [
  { Id: "12", Brand: "Benross" },
  { Id: "8", Brand: "Callaway" },
  { Id: "11", Brand: "Cobra" },
  { Id: "2", Brand: "Golf Pride" },
  { Id: "15", Brand: "HONMA" },
  { Id: "3", Brand: "IOMIC" },
  { Id: "13", Brand: "Krank" },
  { Id: "4", Brand: "Lamkin" },
  { Id: "14", Brand: "Mizuno" },
  { Id: "1", Brand: "Nike" },
  { Id: "6", Brand: "Ping" },
  { Id: "16", Brand: "PXG" },
  { Id: "9", Brand: "TaylorMade" },
  { Id: "7", Brand: "Titleist" },
  { Id: "10", Brand: "Wilson Staff" },
  { Id: "5", Brand: "Winn" },
];

export const GetBrandsList = async () => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.BRANDS.GET_BRAND}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
