import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  Box,
  Alert,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  IconButton,
  Backdrop,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { darken, lighten, styled } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { ClubList } from "../../data/clublist";
import { ClubCvsList } from "../../data/clubcsvdata";
import ClubManagementEditData from "./edit-brand-data";
import { UpsertClub, GetModelsDataForEdit } from "../../services/Club.Service";
import { GetBrandsList } from "../../services/Brand.service";
import { useProSidebar } from "react-pro-sidebar";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

import zIndex from "@mui/material/styles/zIndex";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const ClubManagement = () => {
  const confirm = useConfirm();

  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [selectedClubData, setSelectedClubData] = React.useState([]);
  const [FileError, setFileeError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [BrandList, setBrandList] = React.useState([]);
  const [SelectedBrand, setSelectedBrand] = useState(null);
  const [SelectedBrandImport, setSelectedBrandImport] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [APIData, setAPIData] = useState(null);
  const [SourceDataChanged, setSourceDataChanged] = React.useState(false);

  const [DataReFetech, setDataReFetech] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let uploadBtnDibaled =
    selectedFile != null &&
    FileError.trim().length <= 0 &&
    SelectedBrandImport != null;

  const getBrands = async () => {
    try {
      var response = await GetBrandsList();
      if (response?.isSuccess == true) {
        var BrandData = [];
        response?.result.forEach((element) => {
          BrandData.push({
            Id: element.Id,
            Brand: JSON.parse(element.brandJson).Brand,
            categories: JSON.parse(element.brandJson).categories,
          });
        });
        setBrandList(BrandData);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getBrands();
    collapseSidebar();
  }, []);

  const getModelData = async () => {
    setAPIData(null);
    var response = await GetModelsDataForEdit({
      brandId: SelectedBrand.Id,
      categoryId: SelectedCategory,
      logId: 0,
    });
    // console.log(response);
    if (response.isSuccess == true) {
      setAPIData(response.result);
    } else {
      toast.error(response?.message);
    }
  };

  React.useEffect(() => {
    SelectedCategory && getModelData();
  }, [SelectedCategory, DataReFetech]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file.type === "text/csv" ||
      file.name.endsWith(".csv") ||
      file.type === "application/json" ||
      file.name.endsWith(".json")
    ) {
      setFileeError("");
      setSelectedFile(file);
      console.log("Selected file:", file);
    } else {
      setFileeError("Please select a CSV file.");
      setSelectedFile(null);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!selectedFile) {
      setFileeError("Please select a CSV file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("clubName", "");
      formData.append("brandName", SelectedBrandImport.Brand);
      formData.append("brandId", SelectedBrandImport.Id);
      formData.append("categoryId", "1");

      var response = await UpsertClub(formData);

      if (response.isSuccess == true) {
        var ReceivedData = response?.result;
        var category = SelectedBrandImport.categories;
        var BrandName = SelectedBrandImport.Brand;
        navigate("/club-management/import", {
          state: { ReceivedData, SelectedBrandImport, category, BrandName },
        });
      } else {
        setErrorMessage(response.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 7000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GroupItems = styled("ul")({ padding: 0 });
  const [selectedClub, setClubValue] = React.useState(null);
  const [inputClubValue, setInputClubValue] = React.useState("");
  const [ErrorMessage, setErrorMessage] = useState(null);
  const [pageTitle, setPageTitle] = React.useState("Select a Category");

  return (
    <Box m="20px" height="75vh">
      <Grid container spacing={3} columns={12}>
        <Grid item xs={3}>
          <Autocomplete
            id="grouped-demo1"
            options={BrandList}
            value={SelectedBrand}
            onChange={(event, newValue) => {
              if (SourceDataChanged) {
                confirm({
                  description:
                    "You have unsaved changes, are you sure you want to leave ?",
                })
                  .then(() => {
                    setSelectedBrand(newValue);
                    setSelectedCategory(null);
                    setSourceDataChanged(false);
                    setAPIData(null);
                  })
                  .catch(() => {});
              } else {
                setSelectedBrand(newValue);
                setSelectedCategory(null);
                setSourceDataChanged(false);
                setAPIData(null);
              }
            }}
            getOptionLabel={(ClubList) => ClubList.Brand}
            sx={{ width: { sm: "100%" } }}
            renderInput={(params) => (
              <TextField {...params} label="Select Brand" />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h2" component="h3" sx={{ textAlign: "center" }}>
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={handleOpen}
            startIcon={<DownloadOutlinedIcon />}
          >
            Import Data
          </Button>
        </Grid>
        <Grid item xs={4} lg={3} xl={2}>
          {SelectedBrand && (
            <Paper
              elevation={0}
              sx={{ maxWidth: 256, backgroundColor: "#F2F0F0" }}
            >
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      backgroundColor: "#F2F0F0",
                      color: "#B20021",
                      fontSize: 22,
                    }}
                  >
                    Category
                  </ListSubheader>
                }
              >
                {SelectedBrand &&
                  SelectedBrand.categories
                    .sort((a, b) => a.categoryId - b.categoryId)
                    .map((brandItem) => (
                      <ListItemButton
                        key={brandItem?.categoryId}
                        selected={SelectedCategory === brandItem?.categoryId}
                        onClick={(event) => {
                          if (SourceDataChanged) {
                            confirm({
                              description:
                                "You have unsaved changes, are you sure you want to leave ?",
                            })
                              .then(() => {
                                setSelectedCategory(brandItem.categoryId);
                                setSourceDataChanged(false);
                              })
                              .catch(() => {});
                          } else {
                            setSelectedCategory(brandItem.categoryId);
                            setSourceDataChanged(false);
                          }
                        }}
                      >
                        <ListItemText primary={brandItem?.category} />
                      </ListItemButton>
                    ))}
              </List>
            </Paper>
          )}
        </Grid>
        <Grid item xs={8} lg={9} xl={10}>
          {APIData != null ? (
            <ClubManagementEditData
              ClubCvsList={APIData}
              setSourceDataChanged={setSourceDataChanged}
              DataReFetech={DataReFetech}
              setDataReFetech={setDataReFetech}
              category={SelectedBrand.categories}
              BrandName={SelectedBrand.Brand}
            />
          ) : SelectedCategory != null ? (
            <h1>Still Loading….</h1>
          ) : null}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
        disableEscapeKeyDown={true}
        sx={{ zIndex: 10 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h3"
              style={{ marginBottom: 20 }}
            >
              Import Custom-Fit Data
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ color: "grey.500" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Stack spacing={2}>
            <Autocomplete
              id="grouped-demo"
              options={BrandList}
              value={SelectedBrandImport}
              onChange={(event, newValue) => {
                setSelectedBrandImport(newValue);
              }}
              getOptionLabel={(ClubList) => ClubList.Brand}
              sx={{ width: { sm: "100%" } }}
              renderInput={(params) => (
                <TextField {...params} label="Select Brand" />
              )}
            />

            <InputLabel htmlFor="standard-textname">Upload a file</InputLabel>
            <Input
              type="file"
              autoFocus={true}
              inputProps={{ accept: ".csv, .json, text/csv, application/json" }}
              name="contact"
              sx={{ width: { sm: "100%" } }}
              style={{ marginTop: 5, marginBottom: 10 }}
              onChange={handleFileChange}
              required
            />
            {FileError && <Alert severity="error">{FileError}</Alert>}
            {ErrorMessage && <Alert severity="error">{ErrorMessage}</Alert>}

            <Button
              sx={{ width: { sm: "100%" } }}
              variant="contained"
              tabIndex={-1}
              type="button"
              startIcon={loading ? null : <DownloadOutlinedIcon />}
              disabled={!uploadBtnDibaled}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Import Data"
              )}
            </Button>
          </Stack>
          {/* )}
           </Formik> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default ClubManagement;
