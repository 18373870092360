import axiosInstance from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

export const UpsertClub = async (fromData) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.UPSERTCLUB}`,
      fromData
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const SaveImportData = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.SAVEIMPORTDATA}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const PublishImportData = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.PUBLISHIMPORTDATA}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const GetImportData = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.GETIMPORTDATA}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const GetModelsDataForEdit = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.GETMODELSFOREDIT}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const SaveCustomFitDataEdit = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${API_ENDPOINTS.CLUB.SAVECUSTOMFITDATAEDIT}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const GetSku = async (data) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.CLUB.GETSKU}?searchText=${data}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const getExistingGripList = async (value) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.CLUB.SEARCHGRIP}?searchText=${value}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
