// AuthContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

import API_ENDPOINTS from "./config/apiEndpoints";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import {
  decryptObj,
  decryptToken,
  encryptObj,
} from "./services/global.service";
// interface AuthContextValue {
//   isLoggedIn: boolean;
//   login: (data: LoginPayload) => Promise<ApiResponse<string>>;
//   logout: () => void;
//   fetchMenuList: () => void;
//   loginError: string;
//   loading: boolean;
//   setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
//   setLoginErr: React.Dispatch<React.SetStateAction<string>>;
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
//   resetToken: string;
//   setResetToken: React.Dispatch<React.SetStateAction<any>>;
//   getUserDetails: () => any;
//   setHeader: (data) => any;
//   headerObj: any;
//   MenuListData;
//   setMenuListData;
// }

import axiosInstance, { axiosInstancePublic } from "./services/axiosInstance";

// Define the type for AuthProviderProps
// interface AuthProviderProps {
//   children: ReactNode;
// }
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [MenuListData, setMenuListData] = useState(
    localStorage.getItem("MenuListData")
      ? JSON.parse(localStorage.getItem("MenuListData") || "")
      : []
  );
  const [loginError, setLoginErr] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [resetToken, setResetToken] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [headerObj, setHeaderObj] = useState({});
  const [render, setRender] = useState(0);

  const login = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstancePublic.post(
        `${API_ENDPOINTS.LOGIN.LOGIN_USER}`,
        data
      );
      if (!response?.data?.isSuccess) {
        if (response?.data?.message !== null) {
          setLoginErr(response?.data?.message);
        } else {
          setLoginErr("An error occurred during login.");
        }
      } else {
        const encryptedToken = response?.data?.result?.accessToken;
        // let dataobj = { Token: encryptedToken };
        localStorage.setItem("encryptToken", encryptedToken);
        localStorage.setItem(
          "refreshToken",
          response?.data?.result?.refreshToken
        );

        // const token: any = await axiosInstancePublic.post(
        //   `${API_ENDPOINTS.DECODEToken}`,
        //   null,
        //   { params: dataobj }
        // );
        // localStorage.setItem("token", token?.data);
        let tokenObj = await decryptToken();
        console.log("tokenObj from crypto", tokenObj);
        if (
          tokenObj.Role == "Custom Fit Portal" ||
          tokenObj.Role == "Customfit Admin"
        ) {
          let customEncryption = encryptObj(tokenObj);
          localStorage.setItem("token", customEncryption);
          let customDecryption = decryptObj(customEncryption);
          setUserDetails(customDecryption);
          setIsLoggedIn(true);
        } else {
          {
            setLoginErr(
              "You don't have the sufficient privilege to access this portal"
            );
            setIsLoggedIn(false);

            setLoading(false);
            return { isSuccess: false };
          }
        }
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  const getUserDetails = () => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    let response;
    try {
      if (userDetails) return userDetails;
      else {
        response = decryptObj(localStorage.getItem("token"));
        setTimeout(() => {
          setUserDetails(response);
        }, 1000);
      }
      return response;
    } catch (error) {
      console.log(error);
      return null;
      // localStorage.clear();
      // window.location.href = "/login";
    }
  };

  const setHeader = (obj) => {
    setHeaderObj(obj);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        loginError,
        loading,
        setIsLoggedIn,
        setLoginErr,
        setLoading,
        resetToken,
        setResetToken,
        getUserDetails,
        setHeader,
        headerObj,
        render,
        setRender,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
