import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import { ViewState, EditingState,IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  MonthView,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  DragDropProvider,DateNavigator,TodayButton,Toolbar
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { owners } from '../../data/tasks';
import { appointments, resourcesData } from '../../data/calender/resources';

export default class StoreExperts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: appointments,
      resources: [
        {
          fieldName: 'roomId',
          title: 'Room',
          instances: resourcesData,
        },
        {
          fieldName: 'members',
          title: 'Members',
          instances: owners,
          allowMultiple: true,
        },
      ],
    };

    this.commitChanges = this.commitChanges.bind(this);
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
      }
      if (deleted !== undefined) {
        data = data.filter(appointment => appointment.id !== deleted);
      }
      return { data };
    });
  }

  render() {
    const { data, resources } = this.state;

    return (
      <Box m="20px">
      <Header title="Calendar" subtitle="Full Calendar Interactive Page" />
      <Grid container spacing={2}>
        <Grid xs={12}>
        <Box  style={{ border: "1px solid #f2f0f0",
       
       }}>
        <Scheduler
          data={data} 
          height="95vh" 
          appointmentResourceCell={{ fontSize: "16px" }}
          appointmentContent={{ fontSize: "16px" }}
          
        >
          <ViewState
            defaultCurrentDate="2017-05-25"
          />
          <EditingState
            onCommitChanges={this.commitChanges}
          />
          <EditRecurrenceMenu />
          <IntegratedEditing />
          <MonthView />
          <Appointments />
          <AppointmentTooltip
            showOpenButton
            showDeleteButton
          />
          <AppointmentForm />
         
          <Toolbar className="mycss"  />
          <DateNavigator />
          <TodayButton />
          <Resources
            data={resources}
            mainResourceName="roomId"
          />
          <DragDropProvider />
        </Scheduler>
      </Box>
          </Grid>
        </Grid>
      </Box>
      
    );
  }
}
