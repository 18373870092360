import axiosInstance, { axiosInstanceSecured } from "./axiosInstance";
import axios, { AxiosResponse } from "axios";
import API_ENDPOINTS from "../config/apiEndpoints";

// interface ApiResponse {
//   map(arg0: (user: any, index: any) => any): unknown;
//   result: any;
//   data: any;
// }
// adds the data to the db

export const change_Password = async (data) => {
  try {
    console.log(data);
    const response = await axiosInstanceSecured.put(
      `${API_ENDPOINTS.LOGIN.CHANGE_PASSWORD}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    console.log("backend", data);
    const response = await axiosInstance.put(
      `${API_ENDPOINTS.USERS.UPDATEUSERSPASSWORD}?userID=${data.userID}&password=${data.password}`
    );
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};
export const getUserImageByEmail = async (email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.USERS.GETUSERIMAGEBYEMAIL}?email=${email}`
    );

    return response.data;
    console.log("edit click", response.data);
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

// export const updateUserImage = async (
//   imageFormData: FormData
// ): Promise<any> => {
//   try {
//     const response: AxiosResponse = await axiosInstance.post(
//       API_ENDPOINTS.USERS.UPDATEUSERIMAGE,
//       imageFormData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",

//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error updating user image:", error);
//     throw error;
//   }
// };

export const updateUser = async (data) => {
  try {
    const response = await axiosInstanceSecured.put(
      `${API_ENDPOINTS.USERS.UPDATEUSERS}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};
